import getMetricsPublisher from './katalMetricsPublisher';

function globalErrorHandler(error) {
    const publisher = getMetricsPublisher().newChildActionPublisherForMethod('GlobalErrorHandler');
    publisher.publishCounterMonitor('error', 1);
    publisher.publishStringTruncate('errorMessage', error.toString());
}

export default {
    install(app) {
        app.config.errorHandler = globalErrorHandler;
    },
};
