<template>
    <div
        :class="{
            'favorite-page-button': true,
            'favorite-page-button-hidden': !showFavoritePageButton,
        }"
        tabindex="0"
        @click.prevent.stop="toggleFavoritePage"
        @keyup.enter.prevent.stop="toggleFavoritePage"
        role="button"
        :aria-label="$t(isFavoritePage ? 'favoritePages_pageRemoveButton' : 'favoritePages_pageAddButton')"
    >
        <KatIcon :name="isFavoritePage ? 'bookmark' : 'bookmark_border'" size="tiny" />
    </div>
</template>

<script>
import KatIcon from '../katal/KatIcon.vue';
import { useFavoritePagesStore } from '../../stores/favoritePagesStore';
import { mapActions, mapState } from 'pinia';
import { useToastStore } from '../../stores/toastStore';

export default {
    components: {
        KatIcon,
    },
    props: {
        navItem: {
            type: Object,
            required: true,
        },
        isFocused: {
            type: Boolean,
            required: true,
        },
        isHovered: {
            type: Boolean,
            require: true,
        },
    },
    computed: {
        ...mapState(useFavoritePagesStore, ['getIsFavoritePage']),
        isFavoritePage() {
            return this.getIsFavoritePage(this.navItem.menuId);
        },
        showFavoritePageButton() {
            return this.isFavoritePage || this.isFocused || this.isHovered;
        },
    },
    methods: {
        ...mapActions(useFavoritePagesStore, ['addFavoritePage', 'removeFavoritePage']),
        ...mapActions(useToastStore, ['setToast']),
        async toggleFavoritePage() {
            if (this.isFavoritePage) {
                this.setToast(this.$t('favoritePages_toast_pageRemoved'));
                await this.removeFavoritePage(this.navItem.menuId);
            } else {
                this.setToast(this.$t('favoritePages_toast_pageAdded'));
                await this.addFavoritePage({
                    pageId: this.navItem.menuId,
                    label: this.navItem.text,
                    path: this.navItem.url,
                    ref: this.navItem.ref,
                    visible: true,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.favorite-page-button {
    &-hidden {
        opacity: 0; // Hides the element but still allows it to be focusable by the keyboard
    }

    &:hover {
        background-color: $zircon;
    }

    &:active,
    &:focus,
    &:focus-within {
        box-shadow: inset 0 0 0 2px $mystic;
        outline: none;
    }
}
</style>
