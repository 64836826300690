import KatalMetricHttpRequest from '@amzn/katal-metrics/lib/metricObject/KatalMetricHttpRequest';
import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import enUS from '../../../translations/common-en-US.puff.json';
import convertPuffJToVueI18n from './convertPuffJToVueI18n';
import getMetricsPublisher from './katalMetricsPublisher';

const LOCALE_PATTERN = /^[a-z]{2}-[A-Z]{2}$/;

// Uses Webpack's dynamic import to lazily load the translations file, only importing the locale we need.
// https://vue-i18n.intlify.dev/guide/advanced/lazy.html
async function loadLocaleMessages(i18n) {
    const locale = getLocale();
    const httpMetrics = new KatalMetricHttpRequest('trim.translations').withMonitor(true);
    await fetch(`/trim/translations/common-${locale}.puff.json`)
        .then((response) => response.json())
        .then((data) => {
            i18n.global.setLocaleMessage(locale, data);
            httpMetrics.setSuccess();
        })
        .catch((error) => {
            // If we reach this block it means the file does not exist or we saw an S3 error.
            console.log(`An error occured when loading translations file for locale [${locale}]: ${error}`);
        })
        .finally(() => {
            getMetricsPublisher().newChildActionPublisherForMethod('AjaxRequests').publish(httpMetrics);
        });
    return nextTick();
}

async function initVueI18n() {
    const locale = getLocale();
    const i18n = createI18n({
        locale: locale,
        fallbackLocale: 'en-US',
        messages: {
            // Only en-US is included in the main bundle, all others are loaded dynamically when needed to reduce the payload's size
            'en-US': convertPuffJToVueI18n(enUS.resources),
        },
        // This callback is called when no translation could be found for either the current locale or en-US. This
        // results in the key itself being displayed to the user, which is very bad! This would only happen if either
        // the key isn't present in common.puff.json or "panther translate" wasn't run after adding it.
        missing: (locale, key) => {
            const publisher = getMetricsPublisher().newChildActionPublisherForMethod('VueI18n');
            publisher.publishCounterMonitor('invalidTranslationKey', 1);
            publisher.publishCounterMonitor(`invalidTranslationKey-${key}`, 1);
        },
    });
    if (locale !== 'en-US') {
        await loadLocaleMessages(i18n);
    }
    return i18n;
}

export function getLocale() {
    const lang = document.documentElement.lang;
    return LOCALE_PATTERN.test(lang) ? lang : 'en-US';
}

export default initVueI18n;
