<template>
    <div class="favorite-pages-editor-container">
        <div class="favorite-pages-editor">
            <div class="favorite-pages-editor-pages">
                <div
                    v-for="(page, index) of favoritePagesMutable"
                    :id="`favorite-page-id-${page.pageId}`"
                    :class="['drop-zone', 'favorite-page-item', { draggingOpacity: isBeingDragged(page) }]"
                    :data-page-index="index"
                    @dragover="onDrag($event)"
                    :key="page.pageId"
                >
                    <div class="draggable-item" draggable="true" @dragstart="startDrag(page)" @dragend="endDrag()">
                        <KatIcon class="draggable-icon" name="draggable" size="small" />
                        <span :class="{ label: true, 'inaccessible-favorite': !page.visible }">
                            {{ page.label }}
                        </span>
                        <KatButton
                            :id="`favorite-page-id-${page.pageId}-remove`"
                            class="remove-button"
                            icon="close"
                            :key="`close-icon-${page.pageId}`"
                            size="small"
                            variant="dark-secondary"
                            :on-click="() => onClickRemove(page.pageId)"
                        />
                    </div>
                </div>
            </div>
            <div class="favorite-pages-editor-buttons">
                <KatButton
                    ref="cancelButton"
                    id="favorite-pages-cancel-button"
                    :label="$t('common_button_cancel')"
                    size="small"
                    variant="dark-secondary"
                    :on-click="onCancel"
                />
                <KatButton
                    id="favorite-pages-save-button"
                    :label="$t('common_button_save')"
                    size="small"
                    variant="dark-primary"
                    :on-click="onSave"
                />
            </div>
        </div>
        <div v-if="hasAnyNonVisiblePage" class="favorite-pages-editor-info">
            {{ $t('favoritePages_header_editFavoritesAdditionalInfo') }}
        </div>
    </div>
</template>

<script>
import KatIcon from '../katal/KatIcon.vue';
import KatButton from '../katal/KatButton.vue';
import { mapActions } from 'pinia';
import { useFavoritePagesStore } from '../../stores/favoritePagesStore';

export default {
    components: {
        KatIcon,
        KatButton,
    },
    data() {
        return {
            favoritePagesMutable: [...this.favoritePages],
            draggingPage: undefined,
            targetedPageIndex: undefined,
        };
    },
    props: {
        favoritePages: {
            type: Array,
            required: true,
        },
    },
    methods: {
        ...mapActions(useFavoritePagesStore, ['editFavoritePages']),
        onCancel() {
            this.$metrics.newChildActionPublisherForMethod('FavoritePagesCancelEdit').publishCounterMonitor('click', 1);
            this.$emit('close');
        },
        onClickRemove(pageId) {
            this.favoritePagesMutable = this.favoritePagesMutable.filter((page) => page.pageId !== pageId);
        },
        onSave() {
            this.editFavoritePages(this.favoritePagesMutable);
            this.$emit('close');
        },
        startDrag(page) {
            this.draggingPage = Object.assign(page);
        },
        onDrag(event) {
            event.preventDefault();
            this.targetedPageIndex = this.getDragEventTargetIndex(event);
            this.movePageFromOneIndexToAnother(this.getIndexOfPage(this.draggingPage.pageId), this.targetedPageIndex);
        },
        endDrag() {
            this.draggingPage = undefined;
            this.targetedPageIndex = undefined;
        },
        getDragEventTargetIndex(event) {
            const node = event.target.closest('.drop-zone');
            const { left, right } = node.getBoundingClientRect();
            const horizontalMidpoint = (left + right) / 2;
            const leftOfCenter = event.clientX <= horizontalMidpoint;
            const originalIndex = parseInt(node.getAttribute('data-page-index'));
            return leftOfCenter ? originalIndex : originalIndex + 1;
        },
        getIndexOfPage(pageId) {
            return this.favoritePagesMutable.map((page) => page.pageId).indexOf(pageId);
        },
        movePageFromOneIndexToAnother(fromIndex, toIndex) {
            const offset = fromIndex < toIndex ? -1 : 0;
            var element = this.favoritePagesMutable[fromIndex];
            this.favoritePagesMutable.splice(fromIndex, 1); // delete item being moved from old position
            this.favoritePagesMutable.splice(toIndex + offset, 0, element); // insert item being moved in new position
        },
        isBeingDragged(page) {
            return this.draggingPage && this.draggingPage.pageId === page.pageId;
        },
    },
    watch: {
        favoritePages(newPages, oldPages) {
            // Append any pages that were added
            const oldPagesIds = new Set(oldPages.map((page) => page.pageId));
            const addedPages = new Set(newPages.filter((page) => !oldPagesIds.has(page.pageId)));
            if (addedPages.size > 0) {
                this.favoritePagesMutable = [...this.favoritePagesMutable, ...addedPages];
            }
            // Filter out any pages that were removed
            const newPagesIds = new Set(newPages.map((page) => page.pageId));
            const removedPageIds = new Set([...oldPagesIds].filter((pageId) => !newPagesIds.has(pageId)));
            if (removedPageIds.size > 0) {
                this.favoritePagesMutable = this.favoritePagesMutable.filter(
                    (page) => !removedPageIds.has(page.pageId)
                );
            }
        },
    },
    mounted() {
        this.$refs.cancelButton.$el.focus();
    },
    computed: {
        hasAnyNonVisiblePage() {
            return this.favoritePages.some((page) => !page.visible);
        },
    },
};
</script>

<style lang="scss" scoped>
.favorite-pages-editor-container {
    width: 100%;
}

.favorite-pages-editor {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.favorite-pages-editor-info {
    color: white;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.favorite-pages-editor-pages {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    .favorite-page-item {
        padding: 0 4px;
        background-color: $kat-squid-ink-700;
        box-shadow: none;
        color: white;

        .draggable-item {
            border: 1px solid $kat-squid-ink-500;
            height: 24px;
            display: flex;
            align-items: center;
            cursor: grab;
        }

        .draggable-icon {
            margin: 0 0 0 1px;
            width: 10px;
            height: 18px;
        }

        .label {
            padding: 0 5px;
        }

        .remove-button {
            padding: 0;
            border: none;
            outline: none;
            background-color: transparent;

            :deep(.kat-button-icon) {
                filter: invert(42%) sepia(12%) saturate(589%) hue-rotate(172deg) brightness(92%) contrast(87%); // $kat-squid-ink-500
            }

            &:hover {
                background-color: transparent;
            }
        }
    }

    .dragging-opacity {
        opacity: 0.4;
    }

    .inaccessible-favorite {
        color: $submarine;
    }
}

.favorite-pages-editor-buttons {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 0 0 auto;
    margin-left: 18px;
}

#favorite-pages-save-button {
    margin-left: 10px;
}
</style>
