<template>
    <template v-if="isBadgeVisible">
        <KatBadge
            v-if="navItem.dataAttributes && navItem.dataAttributes.badgeLabelText"
            :label="navItem.dataAttributes.badgeLabelText"
            :type="navItem.dataAttributes.badgeType"
        />
        <NavDot v-else :type="navItem.dataAttributes && navItem.dataAttributes.badgeType" />
    </template>
</template>

<script>
import { mapState } from 'pinia';
import { useDismissalsAndExposuresStore } from '../../stores/dismissalsAndExposuresStore';
import getAllChildNavItems from '../../util/getAllChildNavItems';
import KatBadge from '../katal/KatBadge.vue';
import KatIcon from '../katal/KatIcon.vue';
import NavDot from './NavDot.vue';

export default {
    name: 'SideNavBadge',
    components: { KatBadge, KatIcon, NavDot },
    props: {
        navItem: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(useDismissalsAndExposuresStore, ['isDismissed', 'isExpired', 'isLoaded']),
        isBadgeVisible() {
            if (!this.isLoaded()) {
                return false;
            }
            if (this.hasActiveBadge(this.navItem)) {
                return true;
            }
            return getAllChildNavItems(this.navItem).some(this.hasActiveBadge);
        },
    },
    methods: {
        hasActiveBadge(navItem) {
            return (
                navItem.dataAttributes &&
                navItem.dataAttributes.badgeType &&
                !this.isDismissed(navItem.menuId) &&
                !this.isExpired(navItem.menuId)
            );
        },
    },
};
</script>
