<template>
    <div id="vc-footer" class="vc-style-aui-anchors">
        <div class="vc-footer-top">
            <a
                v-if="site === Site.VENDOR_CENTRAL && TERMS_OF_USE_MARKETSCOPES.includes(marketscope)"
                class="terms-of-use"
                href="https://s3.amazonaws.com/vendorcentral/EN/legal/Vendor_Terms_of_Use.pdf"
            >
                {{ $t('footer_termsOfUse') }}
            </a>
            <span class="copyright-policy">{{
                $t('footer_copyrightPolicy', {
                    currentYear: new Date().getFullYear(),
                })
            }}</span>
        </div>
        <span v-if="COOKIE_POLICY_MARKETSCOPES.includes(marketscope)" class="cookie-policy">
            <i18n-t keypath="footer_cookiePolicy_statement" tag="label" for="footer_cookiePolicy_notice">
                <a :href="cookieURL">
                    {{ $t('footer_cookiePolicy_notice') }}
                </a>
            </i18n-t>
        </span>
        <TheHMD v-if="isAuthorized && !isInSubmitted" />
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { Site } from '../enums/Site';
import { useContextStore } from '../stores/contextStore';
import { useVendorStatusStore } from '../stores/vendorStatusStore';
import TheHMD from './TheHMD.vue';

export const TERMS_OF_USE_MARKETSCOPES = Object.freeze(['US', 'CA', 'MX']);

export const COOKIE_POLICY_MARKETSCOPES = Object.freeze(['DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE']);

export default {
    name: 'TheFooter',
    components: {
        TheHMD,
    },
    data: function () {
        return {
            Site,
            TERMS_OF_USE_MARKETSCOPES,
            COOKIE_POLICY_MARKETSCOPES,
        };
    },
    computed: {
        ...mapState(useContextStore, ['site', 'marketscope', 'isAuthorized']),
        ...mapState(useVendorStatusStore, ['isInSubmitted']),
        shouldMountDelphiChat() {
            return this.site === Site.VENDOR_CENTRAL;
        },
        cookieURL() {
            return (
                window.location.origin +
                '/gp/help/customer/display.html?ie=UTF8&nodeId=201890250&ref_=footer_cookies_notice'
            );
        },
    },
    methods: {
        getDelphiChatUrl() {
            if (this.$stage === 'beta') {
                return 'https://d1cvcfnq27uns3.cloudfront.net/delphi_loader.latest.js';
            } else if (this.$region === 'USAmazon') {
                return 'https://d1q6cce2onauix.cloudfront.net/delphi_loader.latest.js';
            } else if (this.$region === 'EUAmazon') {
                return 'https://d1nq8j8gzig2lv.cloudfront.net/delphi_loader.latest.js';
            }
            return 'https://d2956k9zt4eyhm.cloudfront.net/delphi_loader.latest.js';
        },
        mountDelphiChat() {
            if (this.shouldMountDelphiChat) {
                const scriptEl = document.createElement('script');
                scriptEl.setAttribute('src', this.getDelphiChatUrl());
                scriptEl.id = 'delphi-chat-script';
                document.head.appendChild(scriptEl);
            }
        },
    },
    mounted() {
        this.mountDelphiChat();
    },
};
</script>

<style lang="scss" scoped>
#vc-footer {
    width: 100%;
    align-items: center;
    background-color: #f4f4f4;
    font-family: $font-family-base;

    .terms-of-use {
        padding-right: 3px;
    }
}

.vc-footer-top {
    display: flex;
    font-size: 14px;
    justify-content: center;
    line-height: 20px;
    padding-bottom: 10px;

    a {
        font-size: 14px;
        line-height: 20px;
    }
}

.cookie-policy {
    display: flex;
    font-size: 12px;
    line-height: 16px;
    white-space: normal;
    margin-left: 110px;
    text-align: center;
}
</style>
