<template>
    <span :class="['kat-badge-root', `kat-badge-${computedType}`]">
        {{ label }}
    </span>
</template>

<script>
export default {
    name: 'KatBadge',
    props: {
        label: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
        },
    },
    computed: {
        computedType() {
            return ['info', 'success', 'warning'].includes(this.type) ? this.type : 'default';
        },
    },
};
</script>

<style lang="scss" scoped>
.kat-badge {
    &-root {
        border-radius: 32px;
        box-sizing: border-box;
        color: white;
        display: inline-flex;
        flex: 1 1 auto;
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: 400;
        height: 20px;
        letter-spacing: 0.06px;
        line-height: 12px;
        overflow: hidden;
        padding: 4px 8px;
        text-align: center;
        white-space: nowrap;
    }

    &-default {
        background-color: $nordic;
    }

    &-info {
        background-color: $eastern;
    }

    &-success {
        background-color: $fiji;
    }

    &-warning {
        background-color: $bloodorange;
    }
}
</style>
