<template>
    <div v-click-outside="closeFlyout">
        <div
            class="flyout-trigger-wrap"
            @mouseenter="openFlyoutIfNotDisabled"
            @mouseleave="closeFlyoutIfNotDisabled"
            :tabindex="tabIndex"
            @focus="openFlyoutIfNotDisabled"
            @blur="closeFlyoutIfNotDisabled"
            :aria-label="triggerAriaLabel"
        >
            <slot name="trigger"></slot>
        </div>
        <!-- Using v-show here to keep the 'flyout' class in the DOM for Popper. -->
        <div
            v-show="isOpen"
            class="flyout-flyout"
            ref="flyout"
            @mouseenter="openFlyout"
            @mouseleave="closeFlyoutMouseLeave"
            @focusin="openFlyout"
            @focusout="closeFlyoutFocusOut"
        >
            <div class="flyout-content-wrap">
                <!-- Using v-if here to avoid the creation of the slot component until the trigger is clicked. -->
                <!-- closeFlyout will allow the slot content to close the flyout, e.g. after a user action. -->
                <slot v-if="isOpen" :closeFlyout="closeFlyoutImmediately"></slot>
            </div>
            <div :class="`flyout-arrow flyout-arrow-${placement}`" data-popper-arrow />
            <!-- This bridges the gap between the trigger and the flyout, so it's not dismissed as you move your -->
            <!-- mouse from one to the other. -->
            <div class="flyout-hoverable-area" />
        </div>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import ClickOutside from 'vue-click-outside';

export default {
    data: function () {
        return {
            isOpen: false,
            popper: null,
            contentResizeObserver: null,
            triggerResizeObserver: null,
            timeout: null,
        };
    },
    props: {
        disableTrigger: {
            type: Boolean,
            default: false,
        },
        forceOpen: {
            type: Boolean,
            default: false,
        },
        isFocusable: {
            type: Boolean,
            default: true,
        },
        triggerAriaLabel: String,
        placement: {
            type: String,
            default: 'bottom',
        },
        offsetDistance: {
            type: Number,
            default: 10,
        },
    },
    computed: {
        tabIndex() {
            return this.isFocusable ? 0 : -1;
        },
        popperOptions() {
            const offsetDistance = this.offsetDistance;
            return {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'arrow',
                        options: {
                            padding: 2,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            altAxis: true,
                            padding: 10,
                        },
                    },
                    {
                        name: 'offset',
                        options: {
                            offset({ placement, popper }) {
                                // For RTL locales, the flyout should be flipped to the other side of the trigger.
                                // Popper.js does not correct for this, so we need to override the offset distance.
                                const hasHorizPlacement = placement.startsWith('left') || placement.startsWith('right');
                                if (document.dir === 'rtl' && hasHorizPlacement) {
                                    return [0, -popper.width - offsetDistance];
                                } else {
                                    return [0, offsetDistance];
                                }
                            },
                        },
                    },
                ],
            };
        },
    },
    methods: {
        openFlyout() {
            clearTimeout(this.timeout);
            if (!this.isOpen) {
                this.isOpen = true;
                this.createPopper();
                this.createResizeObservers();
            }
        },
        openFlyoutIfNotDisabled() {
            if (!this.disableTrigger) {
                this.openFlyout();
            }
        },
        closeFlyoutMouseLeave() {
            // Don't close the flyout if it's currently focused by the keyboard
            if (document.activeElement && this.$refs.flyout.contains(document.activeElement)) {
                return;
            }
            this.closeFlyout();
        },
        closeFlyoutFocusOut(event) {
            // Only close the flyout if an element outside of it is receiving focus
            if (event.relatedTarget == null || this.$refs.flyout.contains(event.relatedTarget)) {
                return;
            }
            this.closeFlyout();
        },
        closeFlyout() {
            if (!this.isOpen) {
                return;
            }
            this.timeout = setTimeout(() => {
                this.isOpen = false;
                this.destroyPopper();
                this.destroyResizeObservers();
                this.$emit('close');
            }, 100);
        },
        closeFlyoutIfNotDisabled() {
            if (!this.disableTrigger) {
                this.closeFlyout();
            }
        },
        closeFlyoutImmediately() {
            if (!this.isOpen) {
                return;
            }
            this.isOpen = false;
            this.destroyPopper();
            this.destroyResizeObservers();
            this.$emit('close');
        },
        createPopper() {
            this.destroyPopper();
            const triggerWrap = this.$el.querySelector('.flyout-trigger-wrap');
            const flyout = this.$el.querySelector('.flyout-flyout');
            this.popper = createPopper(triggerWrap, flyout, this.popperOptions);
        },
        destroyPopper() {
            if (this.popper) {
                this.popper.destroy();
                this.popper = null;
            }
        },
        updatePopper() {
            if (this.popper) {
                this.popper.update();
            }
        },
        createResizeObservers() {
            this.destroyResizeObservers();
            this.contentResizeObserver = new ResizeObserver(this.updatePopper);
            const contentWrap = this.$el.querySelector('.flyout-content-wrap');
            this.contentResizeObserver.observe(contentWrap);
            this.triggerResizeObserver = new ResizeObserver(this.updatePopper);
            const triggerWrap = this.$el.querySelector('.flyout-trigger-wrap');
            this.triggerResizeObserver.observe(triggerWrap);
        },
        destroyResizeObservers() {
            if (this.contentResizeObserver) {
                this.contentResizeObserver.disconnect();
                this.contentResizeObserver = null;
            }
            if (this.triggerResizeObserver) {
                this.triggerResizeObserver.disconnect();
                this.triggerResizeObserver = null;
            }
        },
    },
    directives: {
        ClickOutside,
    },
    watch: {
        forceOpen(newForceOpen) {
            if (newForceOpen) {
                this.openFlyout();
            } else {
                this.closeFlyoutImmediately();
            }
        },
    },
    mounted() {
        if (this.forceOpen) {
            this.openFlyout();
        }
    },
};
</script>

<style lang="scss" scoped>
.flyout-trigger-wrap {
    display: flex;
}

.flyout-flyout {
    background-color: white;
    border: 1px solid $kat-squid-ink-200;
    border-radius: 1px;
    position: relative;
    z-index: 100;
}

.flyout-content-wrap {
    background-color: white;
    position: relative;
    z-index: 1;
}

.flyout-arrow,
.flyout-arrow::before {
    height: 8px;
    position: absolute;
    left: -1px;
    width: 8px;
}

.flyout-arrow {
    &-bottom {
        top: -5px;
    }

    &-right {
        left: -5px;
    }

    &-left {
        right: -5px;
    }

    &-top {
        bottom: -5px;
    }
}

.flyout-arrow::before {
    background: white;
    border: 1px solid $kat-squid-ink-200;
    border-radius: 1px;
    content: '';
    transform: rotate(45deg);
}

.flyout-hoverable-area {
    inset: -10px 0 100%;
    position: absolute;
}
</style>
