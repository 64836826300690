<template>
    <span :class="['nav-dot-root', `nav-dot-${computedType}`]" />
</template>

<script>
export default {
    name: 'NavDot',
    props: {
        type: {
            type: String,
        },
    },
    computed: {
        computedType() {
            return ['info', 'success', 'warning'].includes(this.type) ? this.type : 'default';
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-dot {
    &-root {
        border-radius: 50%;
        height: 8px;
        width: 8px;
        color: white;
    }

    &-default {
        background-color: $nordic;
    }

    &-info {
        background-color: $eastern;
    }

    &-success {
        background-color: $fiji;
    }

    &-warning {
        background-color: $bloodorange;
    }
}
</style>
