<template>
    <div class="settings-list" data-test-tag="settings-menu">
        <LoadingWrapper v-if="isAuthorized && !isInSubmitted" :loading-state="loadingState">
            <SettingsListItem
                v-for="menuItem in (settingsMenu && settingsMenu.subNav) || []"
                :key="menuItem.menuId"
                :label="menuItem.text"
                :menuId="menuItem.menuId"
                :uri="menuItem.url"
                :reftag="menuItem.ref"
                @click.native="onClickMenuItem(menuItem.menuId)"
            />
        </LoadingWrapper>
        <SettingsListItem
            v-if="showShowFavoritePagesMenuItem"
            data-test-tag="show-favorite-pages"
            :label="$t('favoritePages_settings_showFavoritesBar')"
            menuId="show-favorite-pages"
            uri="#"
            @click.prevent="onClickShowFavoritePages"
        />
        <SettingsListItem
            :label="$t('header_right_signOutTitleCase')"
            menuId="sign-out"
            :uri="SIGN_OUT_URL"
            @click.native="onClickMenuItem('logout')"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { SIGN_OUT_URL } from '../../constants/urls';
import { useContextStore } from '../../stores/contextStore';
import { useNavigationStore } from '../../stores/navigationStore';
import { useVendorStatusStore } from '../../stores/vendorStatusStore';
import LoadingWrapper from './LoadingWrapper.vue';
import SettingsListItem from './SettingsListItem.vue';
import { useFavoritePagesStore } from '../../stores/favoritePagesStore';
import { LoadingStates } from '../../enums/LoadingStates';

export default {
    props: {
        closeFlyout: {
            type: Function,
            required: false,
        },
    },
    data() {
        return {
            openTime: null,
        };
    },
    computed: {
        ...mapState(useNavigationStore, ['loadingState', 'settingsMenu']),
        ...mapState(useFavoritePagesStore, {
            favoritePagesHidden: 'hidden',
            favoritePagesLoadingState: 'loadingState',
        }),
        ...mapState(useContextStore, ['isAuthorized']),
        ...mapState(useVendorStatusStore, ['isInSubmitted']),
        SIGN_OUT_URL() {
            return SIGN_OUT_URL;
        },
        showShowFavoritePagesMenuItem() {
            return (
                this.isAuthorized &&
                !this.isInSubmitted &&
                this.favoritePagesLoadingState === LoadingStates.loaded &&
                this.favoritePagesHidden
            );
        },
    },
    methods: {
        ...mapActions(useNavigationStore, ['loadNavigation']),
        ...mapActions(useFavoritePagesStore, ['showFavoritePages']),
        onClickMenuItem(menuId) {
            if (this.openTime) {
                const openDuration = Date.now() - this.openTime;
                this.$metrics
                    .newChildActionPublisherForMethod('RedesignImpression')
                    .publishTimerMonitor(`sc-quicklink-settings.page-${menuId}.click`, openDuration);
                this.openTime = null; // Prevent the 'show' metric from also being logged
            }
            window.sessionStorage.setItem('navmetric-linked-from', `sc-quicklink-settings=${window.ue_id}`);
            this.closeFlyout && this.closeFlyout();
            // Let the browser handle updating the page URL, since it supports opening new tabs or windows
        },
        onClickShowFavoritePages() {
            this.closeFlyout && this.closeFlyout();
            this.showFavoritePages();
        },
    },
    created() {
        this.loadNavigation();
        this.openTime = Date.now();
    },
    beforeUnmount() {
        if (this.openTime) {
            const openDuration = Date.now() - this.openTime;
            if (openDuration > 100) {
                this.$metrics
                    .newChildActionPublisherForMethod('RedesignImpression')
                    .publishTimerMonitor('sc-quicklink-settings.show', openDuration);
            }
        }
    },
    components: {
        LoadingWrapper,
        SettingsListItem,
    },
};
</script>

<style lang="scss" scoped>
$settings-list-min-width: 192px;

.settings-list {
    color: $kat-squid-ink-700;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    min-width: $settings-list-min-width;

    :deep(.loading-wrapper-error),
    :deep(.loading-wrapper-loading) {
        padding: $settings-list-vert-padding $settings-list-horiz-padding;
        width: $settings-list-min-width;
    }

    &-divider {
        border-top: 1px solid $kat-squid-ink-100;
    }
}
</style>
