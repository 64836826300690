<template>
    <div id="navbar" data-test-tag="navbar-redesign">
        <div class="nav-left-section">
            <div
                v-if="isAuthorized && !isInSubmitted"
                data-test-tag="nav-button-container"
                class="nav-button-container"
            >
                <div
                    data-test-tag="hamburger-icon"
                    class="nav-button"
                    tabindex="0"
                    :aria-label="$t('sideNav_alt_navigationMenu')"
                    ref="hamburgerIcon"
                    role="button"
                    @click="onClickNavButton"
                    @keyup.enter="onClickNavButton"
                    @keyup.space="onClickNavButton"
                >
                    <KatIcon class="nav-button-icon" name="hamburger" size="small" />
                </div>
            </div>
            <a
                href="/"
                aria-label="Amazon"
                class="logo-container"
                data-test-tag="logo-container"
                role="link"
                tabindex="0"
            >
                <TheHeaderLogo />
                <div class="logo-container-divider-right" />
            </a>
            <AccountSwitcherLoader />
        </div>
        <div class="nav-right-section" data-test-tag="nav-right-section">
            <TheUtilityBar />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useContextStore } from '../../stores/contextStore';
import { useSideNavStore } from '../../stores/sideNavStore';
import { useVendorStatusStore } from '../../stores/vendorStatusStore';
import AccountSwitcherLoader from '../AccountSwitcherLoader.vue';
import KatIcon from '../katal/KatIcon.vue';
import TheHeaderLogo from './TheHeaderLogo.vue';
import TheUtilityBar from './TheUtilityBar.vue';
import { FocusTrap } from 'focus-trap-vue';

export default {
    name: 'Navbar',
    components: {
        FocusTrap,
        AccountSwitcherLoader,
        KatIcon,
        TheHeaderLogo,
        TheUtilityBar,
    },
    computed: {
        ...mapState(useContextStore, ['isAuthorized']),
        ...mapState(useVendorStatusStore, ['isInSubmitted']),
    },
    methods: {
        ...mapActions(useSideNavStore, ['openSideNav']),
        onClickNavButton() {
            this.$metrics.newChildActionPublisherForMethod('LeftNavOpen').publishCounterMonitor('click', 1);
            this.openSideNav();
        },
    },
};
</script>

<style lang="scss" scoped>
$divider-right: 1px $kat-squid-ink-500 solid;

#navbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    :deep(.account-switcher-fallback) {
        color: white;
        text-decoration: none;
    }
}

.nav-left-section {
    display: flex;
    flex-direction: row;
    height: $navbar-height;
    margin-right: 9px;
}

.market-switcher-wrapper {
    display: flex;
    justify-content: center;
}

.nav-button-container {
    border-right: $divider-right;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
}

// Total size should equal 36 x 36
.nav-button {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 1px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 7px;
    width: 100%;

    &:hover {
        border-color: white;
        outline: none;
    }

    &:active,
    &:focus {
        border-color: $kat-aqua-300;
        outline: none;
    }

    &-icon {
        filter: invert(1); // Makes the icon white
    }
}

.nav-right-section {
    align-items: center;
    display: flex;
    height: $navbar-height;
    margin-left: 9px;
    margin-right: 18px;
}

.logo-container {
    align-items: center;
    color: white;
    display: flex;
    padding-left: 16px;
    margin-right: 16px;
}

.logo-container-divider-right {
    height: 20px;
    border-right: $divider-right;
    padding-right: 16px;
}

.navbar-account-switcher {
    color: white;
    text-decoration: none;
}
</style>
