export const headerMarginMonsAppAllowList = [
    'ABA',
    'ABALogger',
    'ABAVendorCentralApp',
    'ABCChatWebApp',
    'AcceleratorWolverineHorizonteService',
    'AccountSwitcher',
    'ACCTRFManagementPortalVendorCentral',
    'ACESBaActionPageLiberty',
    'AFFPApplication',
    'AFIShipmentManagerService',
    'AgginKatalMonsApp',
    'AICPortal',
    'AlexandriaDownload',
    'AlexandriaUpload',
    'AmazonImaging',
    'AmazonImagingWebApp',
    'AmazonProductBotWebsite',
    'AmazonServiceLibrary',
    'AmazonServiceLibraryWebsite',
    'AMDEDeveloperRegistrationMonsApp',
    'AmsBALibertyHz',
    'AmsVssApplicationApp',
    'AnchormanNewsService',
    'AndonBALibertyHz',
    'AnsChatWidget',
    'AnsFooterAssets',
    'AnsLifeCycleApp',
    'APAMonsApplication',
    'APANativeMonsApplication',
    'APAUIMonsApplicationTest',
    'APAWebUIMonsApplication',
    'AplusLogger',
    'AplusVCHz',
    'ApmAieLiberty',
    'AppealPortal',
    'ARA',
    'ARAccrualsApplicationApp',
    'ARAccrualsMonsApp',
    'ARGOSellingPartnerPortal',
    'AryaLibertyApp',
    'AshaVendorCentralPortal',
    'AuthenticationPages',
    'AuthenticationPortal',
    'AuthorVendorCentralApplication',
    'AuthorVendorCentralGurupa',
    'AuthorVendorCentralKatalWebsite',
    'AutomatedBusinessPlanning',
    'AutomotiveEUFitmentToolWebsite',
    'AutomotiveGlobalFitmentToolMonsApp',
    'AutomotiveMFM',
    'AvnWebsiteLibertyHz',
    'AVSChatAssets',
    'AVSEssentialsHub',
    'AvsVendorHub',
    'BAActionPagesLibertyHz',
    'BalanceVC',
    'Bowie',
    'BrandAnalyticsUI',
    'BrandCRMUI',
    'BrandSelectionManager',
    'BulkPlatformLibertyHz',
    'Bundle3PUI',
    'BusinessGrowthInsightsDashboardUI',
    'BusinessPricingVendorCentral',
    'BwAMerchantOnboardingKATALAPP',
    'BwP',
    'CasePackManagementVCLiberty',
    'CasinoKnowhereApp',
    'CategoryPickerARestService',
    'CDNProxyRouting',
    'CertificatesMainPageApp',
    'CkpVC',
    'CMBSVCApp',
    'CommitmentsWebsite',
    'CommitmentVCQuery',
    'CommodityBidVCApp',
    'Concessions_VX',
    'ContactUsMons',
    'ContraCogsDefaultRatesKatalApp',
    'ContraCogsLibertyApp',
    'ContraCOGSVCGurupa',
    'CookieMonster',
    'CoralVineService',
    'CouponsVCApp',
    'CPFSeller',
    'Creatorhub',
    'D16GAckbarVCWebApp',
    'DeepLinkTest',
    'DesignCentralDataAccessLayer',
    'DesignCentralUIWebsite',
    'DevNull',
    'DfVendorPickTaskWebsite',
    'DfWebsite',
    'DigitalIngestionAggregator',
    'DirectImportNegotiationPlatform',
    'EDIApplication',
    'EDIIntegMonitoringExtranetApplication',
    'EDISelfServiceUIApp',
    'EPIVendorFeeds',
    'EveVcTranslatePolicyApp',
    'FitIngestionTool',
    'FitProductIngestionToolMonsApp',
    'FitSmart',
    'GestaltMons',
    'GlobalAccountPickerService',
    'GngTestKatalMonsWebsite',
    'GodzukiLibertyApp',
    'GPWidgets',
    'GrapevineEnrollmentService',
    'GrapevineVendorWebsite',
    'GuobenjaMonsWorksop',
    'GurupaPostAuth',
    'HelpHubServiceAPI',
    'HelpHubWebsite',
    'HillService',
    'HillWebsite',
    'HMD',
    'HomePageWidgetApplication',
    'HorizonteA2ISXLibertyWebApp',
    'HorizonteOneByOneGUIWebApp',
    'HuddlesSellerCentralApp',
    'I90Redirect',
    'IamHariSampleMonsAPP',
    'ImageNowVendor',
    'ImagingEducationHub',
    'ImportBookingHz',
    'ImportOrderingLibertyHz',
    'InboundDashboard',
    'InboundShippingQueue',
    'IPVendorPortal',
    'IR2GlobalApp',
    'ISS-Shipment-Manager',
    'JakisWebsite',
    'JeevesApp',
    'JellyfishWeb',
    'JFTC',
    'JICSHub',
    'JonasExternalPortal',
    'KatalCategoryPicker',
    'KindlePublisherSelfServiceWebsite',
    'KsamarthMonsDemo',
    'LegacyVendorPlatformRoutes',
    'LegoVendorCentralTest',
    'LIANBSKY_TEST',
    'LinkIntegrationsGettingStartedApp',
    'LinkSpiralKatalApp',
    'LinkSpiralSpringApp',
    'LinkUEOHzApplication',
    'ListingQualityDashboard',
    'ManageUsers',
    'ManageYourExperimentsUI',
    'ManraySellingPartnerWebsite',
    'Marathon',
    'MarketplaceAuthorizationChecker',
    'MarketplaceHomepage',
    'MerchantPickerMons',
    'MerchantProductSearch',
    'MerchantSurveyLibertyApp',
    'MonarchWeb',
    'MonsAdditionalPlatformServices',
    'MonsAdvantageHomepage',
    'MonsSitbApp',
    'MonsSpringThingDemo',
    'MonsStaticAssets',
    'MonsVCHomepageCasino',
    'MVAL',
    'MVPVendorCentral',
    'MvrBaActionPageLiberty',
    'MYCBulkUploadApp',
    'MYCBulkUploadAppVendorCentral',
    'MYGWebsite',
    'MYIJanusBackend',
    'MYPVendorsBackend',
    'MYPVendorsFrontend',
    'NativeConfiguration',
    'NavigationRendering',
    'NotificationMetricsServiceOFPRewrite',
    'NotificationPreferences',
    'OfficialContributionsLibertyWebApp',
    'OracleBIExtranetARAApplication',
    'PartnerAccountWebApp',
    'PartnerNetwork',
    'PDXIR2_UnifiedOnboarding',
    'POManagementApp',
    'PrivateBrandsCentralConnect',
    'PrivateBrandsCentralDataAccessLayer',
    'ProductSupportPartnerWebApp',
    'ProgressiveListingsService',
    'ProgressiveListingsWebsite',
    'PurchaseOrderManagementGurupa',
    'PurchaseOrderManagementWebsite',
    'PWFComponents',
    'QuasarWebsite',
    'QueryArbiter',
    'QuickList',
    'RappStrategyKatalMockUI',
    'RCAMPPaymentSettingsAPXConfiguration',
    'RCAMPVendorCentralWebapps',
    'RemovalsAuthorizationLibertyApp',
    'RemovalsAuthorizationVCApp',
    'RetailAnalyticsUI',
    'RetailVendorAdvAppsApplication',
    'RetailVendorAppImageUploadApplication',
    'RetailVendorCostManagement',
    'RetailVendorVCContactUsApplication',
    'RetailVendorVCCostManagement',
    'RetailVendorVCCostManagementGurupa',
    'RetailVendorVCDigitalMediaApplication',
    'RetailVendorVCHelpServiceApplication',
    'RetailVendorVCInvoiceManagementApplication',
    'RetailVendorVCItemApplication',
    'RetailVendorVCPasswordResetApplication',
    'RetailVendorVCPaymentsApplication',
    'RetailVendorVCPricing',
    'RetailVendorVCResourceCenterApplication',
    'RetailVendorVCSetupModulesApplication',
    'RetailVendorVCShipmentMgmtApplication',
    'RetailVendorVCVendorSetupApplication',
    'ReturnsDashboard',
    'ReVendorPerformanceDashboard',
    'RnSSPDashboardBackendService',
    'RnSVendorCentral',
    'RocketMan',
    'SampleVCTestAppMVR',
    'SelectionGrowthHub',
    'SellerAppsDiscoveryService',
    'SellerAppsManageYourAppsAssets',
    'SellerAppsMyaOrchService',
    'SellerCentralNotificationServiceKatal',
    'SellerMobileHorizonte',
    'SellerPerformanceDiscoveryUI',
    'SellerPerformanceSellerTrustHealth',
    'SellerVendorRegistrationPage',
    'SellingPartnerDevConsoleOrchestrator',
    'SellingPartnerDeveloperConsole',
    'SellingPartnerDeveloperRegistrationAndAssessment',
    'SellingPartnerDeveloperSelfAuthorize',
    'SGCoreService',
    'ShoppingGuides',
    'SMP',
    'SnDBoxCustomerServicePage',
    'SnDBoxDashboard',
    'SnDBoxProductListingManagement',
    'SnDBoxReportingWebsite',
    'SnSBAWorkflowLiberty',
    'SnSSellingPartnerLogger',
    'SnSSPMiddlewareService',
    'SnSVendorCentral',
    'Solaire',
    'SolariaImageManagementWebApp',
    'SolutionProviderLibrary',
    'SourcingOptimizationVendorCentralWebsite',
    'SPACEIR2CommunicationCenter',
    'SPNOrderGateway',
    'SPNSellerDashboard',
    'SPPaymentsVendorDashboardAssets',
    'SPPSimplifiedInvoiceManagementApp',
    'SpreeAppForDeletedRoutes',
    'SPVendorHoldbacksTransparencyAssetsWebsite',
    'SPVendorHoldbacksTransparencyWebApp',
    'SPVendorPaymentsWebApplication',
    'SPVendorReconAssets',
    'SuggestedDemandQuantityIntakePage',
    'SupplierPerformanceIndigoDashboard',
    'SupplierPortalVC',
    'SupplierShipmentAddressWebsite',
    'SustainabilityFulcrum',
    'SXAthena',
    'SXAugur',
    'TargetedCatalogCompliance',
    'TasklessEDelivery',
    'TaxInterview',
    'TCFVendorCentralWebsite',
    'TestKaseram',
    'TestMgVaibha',
    'TestMonsterMonAppMarketingTools',
    'TestWanlizh',
    'Throughbottom',
    'ThroughBottom',
    'ToasterMonsWorkshop',
    'TransVCShipmentManagerApp',
    'TrimMetadataService',
    'TrimMetadataServiceAccountSwitcher',
    'UPCxTool',
    'UploadMyTemplate',
    'VcbsVcApp',
    'VCDigitalMediaReports',
    'VCGurupaAuthenticationPages',
    'VCHomepageGurupa',
    'VCMHelpLandingApp',
    'VcNotifConfigUIApp',
    'VCSNDashboardApp',
    'VCSNDashboardWebsite',
    'VectrexMobile',
    'VendorAppointmentSchedulerLibertyApp',
    'VendorBankInformationLibertyApp',
    'VendorCentralBulkUpload',
    'VendorCentralBundleManagerUI',
    'VendorCentralFileUpload',
    'VendorCentralMyCatalog',
    'VendorCentralTrim',
    'VendorCentralUnsafeJSPages',
    'VendorDisputeManagementApp',
    'VendorDisputeManagementLibertyApp',
    'VendorDisputeMonsApp',
    'VendorDownloadDashboard',
    'VendorEconomicsVCApp',
    'VendorEconomicsVCLibertyApps',
    'VendorEconomicsVCLibertyAppsLuiTest',
    'VendorGroupSwitcher',
    'VendorInitiatedOrderVCService',
    'VendorInitiatedOrderVCWebsite',
    'VendorInvoiceCreationLibertyApp',
    'VendorInvoiceManagementLibertyApp',
    'VendorItemAvailabilityMonsWebsite',
    'VendorLedgerReportingPOCArestWeb',
    'VendorLiquidation',
    'VendorLiquidationInvoiceApp',
    'VendorLiquidationInvoiceMonsApp',
    'VendorMobileApp',
    'VendorMobileCaselog',
    'VendorNegotiationWebsite',
    'VendorOnboardingGuide',
    'VendorOperationHoursApp',
    'VendorOperationHoursPage',
    'VendorOpportunityDashboard',
    'VendorPerformanceDashboard',
    'VendorPerformanceDashboardExport',
    'VendorPrepInstructionDashboard',
    'VendorReceivablesVCSantanaApp',
    'VendorRemittanceLibertyApp',
    'VendorReturnsBulkDownloadMonsKatal',
    'VendorReturnShipmentVisibility',
    'VendorReturnsLibertyApp',
    'VendorReturnsMonsApp',
    'VendorScorecard',
    'VendorSubmissionsMonsApp',
    'VendorTaxRegistrationApp',
    'VendorTaxRegistrationLibertyApp',
    'VendorTestApps',
    'VendorTestMonsReconcilliation',
    'VendorTraining',
    'VendorUserManagementGurupa',
    'VendorUserManagementHz',
    'VendorWebinar',
    'VendorWelcomePageGurupa',
    'VHDPhase2',
    'Vibes',
    'VineVCHz',
    'VineVendorCentralApplication',
    'VISBulkItemCreationApp',
    'VlwMonsApp',
    'VPSBankInformationApp',
    'VPSContraCOGSApp',
    'VPSNetBalanceApp',
    'VPSVendorSubmissionsApp',
    'VSSAcceptTermsAndConditions',
    'VssAdvertisingService',
    'VSSAppsManageMyItemsApp',
    'VSSBAHomepageLibertyHz',
    'VSSBusinessAdvisorHz',
    'VSSCaseManagementLibertyHz',
    'VSSCatalogFeedsWebUI',
    'VSSCouponsLibertyHz',
    'VSSCreateInvoiceApp',
    'VSSErrorPagesGurupa',
    'VSSGurupaAuthPortalIntegration',
    'VSSGurupaDownload',
    'VSSGurupaJQueryUtils',
    'VSSGurupaPlatformComponents',
    'VSSGurupaUedata',
    'VSSGurupaUnidentified',
    'VSSInternationalAccountsHz',
    'VSSItemBulkUI',
    'VSSItemCatalogLiberty',
    'VSSItemHz',
    'VSSLibertyChromeHz',
    'VSSLibertyExampleHz',
    'VSSLibertyLanding',
    'VSSLibertyOnboardingHz',
    'VSSMarketingRecommendationsLibertyApp',
    'VSSMarketingVCApp',
    'VSSPalletWebsite',
    'VSSPicsTradeoffWebsite',
    'VSSPromotionsLibertyApp',
    'VSSSantanaUedata',
    'VSSSantanaUnidentified',
    'VssSCXRankingsLibertyApp',
    'VSSSupportManagementLibertyHz',
    'VSSTChangeEmailTemplatesApp',
    'VSSTContactUsApp',
    'VSSTCoreApp',
    'VSSTGridApp',
    'VSSTGridExportApp',
    'VSSTHomePageApp',
    'VSSTRtpAndFeebackApp',
    'VSSTScorecardApp',
    'VXReportService',
    'WarehouseTaxSettings',
    'WDVendorCentralApp',
    'WDVendorCentralAppsLiberty',
];
