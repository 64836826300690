export const auiMonsAppAllowList = [
    'AcceleratorWolverineHorizonteService',
    'AccountSwitcher',
    'AFIShipmentManagerService',
    'AgginKatalMonsApp',
    'AICPortal',
    'AmazonProductBotWebsite',
    'AMDEDeveloperRegistrationMonsApp',
    'AmsBALibertyHz',
    'AnchormanNewsService',
    'AnsChatWidget',
    'AnsFooterAssets',
    'AnsLifeCycleApp',
    'APANativeMonsApplication',
    'APAWebUIMonsApplication',
    'AplusLogger',
    'AplusVCHz',
    'ApmAieLiberty',
    'ARA',
    'ARAccrualsMonsApp',
    'ARGOSellingPartnerPortal',
    'AryaLibertyApp',
    'AshaVendorCentralPortal',
    'AuthenticationPortal',
    'AuthorVendorCentralKatalWebsite',
    'AutomatedBusinessPlanning',
    'AutomotiveEUFitmentToolWebsite',
    'AutomotiveGlobalFitmentToolMonsApp',
    'AutomotiveMFM',
    'AvnWebsiteLibertyHz',
    'AVSEssentialsHub',
    'AvsVendorHub',
    'BalanceVC',
    'BrandAnalyticsUI',
    'BrandCRMUI',
    'BrandSelectionManager',
    'BusinessPricingVendorCentral',
    'CasePackManagementVCLiberty',
    'CasinoKnowhereApp',
    'CategoryPickerARestService',
    'CDNProxyRouting',
    'CkpVC',
    'CMBSVCApp',
    'CommitmentsWebsite',
    'CommitmentVCQuery',
    'Concessions_VX',
    'ContactUsMons',
    'ContraCogsDefaultRatesKatalApp',
    'ContraCogsLibertyApp',
    'Creatorhub',
    'D16GAckbarVCWebApp',
    'DesignCentralDataAccessLayer',
    'DesignCentralUIWebsite',
    'DevNull',
    'DfVendorPickTaskWebsite',
    'DfWebsite',
    'DirectImportNegotiationPlatform',
    'EDISelfServiceUIApp',
    'EPIVendorFeeds',
    'FitIngestionTool',
    'GestaltMons',
    'GodzukiLibertyApp',
    'GPWidgets',
    'GrapevineEnrollmentService',
    'GrapevineVendorWebsite',
    'HelpHubServiceAPI',
    'HelpHubWebsite',
    'HillService',
    'HillWebsite',
    'HMD',
    'HorizonteA2ISXLibertyWebApp',
    'HuddlesSellerCentralApp',
    'I90Redirect',
    'ImagingEducationHub',
    'ImportBookingHz',
    'InboundShippingQueue',
    'IPVendorPortal',
    'ISS-Shipment-Manager',
    'JakisWebsite',
    'JellyfishWeb',
    'JICSHub',
    'KatalCategoryPicker',
    'KindlePublisherSelfServiceWebsite',
    'LegacyVendorPlatformRoutes',
    'LinkIntegrationsGettingStartedApp',
    'LinkSpiralKatalApp',
    'LinkSpiralSpringApp',
    'LinkUEOHzApplication',
    'ListingQualityDashboard',
    'ManageYourExperimentsUI',
    'ManraySellingPartnerWebsite',
    'MarketplaceAuthorizationChecker',
    'MonarchWeb',
    'MonsAdditionalPlatformServices',
    'MonsAdvantageHomepage',
    'MonsSitbApp',
    'MonsStaticAssets',
    'MonsVCHomepageCasino',
    'MVPVendorCentral',
    'MYCBulkUploadAppVendorCentral',
    'MYGWebsite',
    'MYIJanusBackend',
    'NavigationRendering',
    'NotificationPreferences',
    'PartnerAccountWebApp',
    'PartnerNetwork',
    'PrivateBrandsCentralDataAccessLayer',
    'ProductSupportPartnerWebApp',
    'PurchaseOrderManagementWebsite',
    'PWFComponents',
    'QuasarWebsite',
    'RCAMPVendorCentralWebapps',
    'RemovalsAuthorizationLibertyApp',
    'RemovalsAuthorizationVCApp',
    'RetailAnalyticsUI',
    'RetailVendorCostManagement',
    'RetailVendorVCCostManagement',
    'RetailVendorVCItemApplication',
    'ReturnsDashboard',
    'ReVendorPerformanceDashboard',
    'RnSSPDashboardBackendService',
    'RnSVendorCentral',
    'RocketMan',
    'SampleVCTestAppMVR',
    'SelectionGrowthHub',
    'SellerAppsDiscoveryService',
    'SellerAppsManageYourAppsAssets',
    'SellerAppsMyaOrchService',
    'SellerCentralNotificationServiceKatal',
    'SellerVendorRegistrationPage',
    'SellingPartnerDevConsoleOrchestrator',
    'SellingPartnerDeveloperConsole',
    'SellingPartnerDeveloperRegistrationAndAssessment',
    'SellingPartnerDeveloperSelfAuthorize',
    'SnDBoxDashboard',
    'SnSSellingPartnerLogger',
    'SnSSPMiddlewareService',
    'SnSVendorCentral',
    'SolariaImageManagementWebApp',
    'SolutionProviderLibrary',
    'SourcingOptimizationVendorCentralWebsite',
    'SPNOrderGateway',
    'SPNSellerDashboard',
    'SPPSimplifiedInvoiceManagementApp',
    'SpreeAppForDeletedRoutes',
    'SPVendorHoldbacksTransparencyAssetsWebsite',
    'SPVendorHoldbacksTransparencyWebApp',
    'SPVendorPaymentsWebApplication',
    'SPVendorReconAssets',
    'SupplierPerformanceIndigoDashboard',
    'SupplierShipmentAddressWebsite',
    'SustainabilityFulcrum',
    'SXAugur',
    'TargetedCatalogCompliance',
    'TasklessEDelivery',
    'TaxInterview',
    'Throughbottom',
    'TrimMetadataService',
    'TrimMetadataServiceAccountSwitcher',
    'UPCxTool',
    'UploadMyTemplate',
    'VCDigitalMediaReports',
    'VCSNDashboardApp',
    'VCSNDashboardWebsite',
    'VectrexMobile',
    'VendorAppointmentSchedulerLibertyApp',
    'VendorBankInformationLibertyApp',
    'VendorCentralBulkUpload',
    'VendorCentralBundleManagerUI',
    'VendorCentralMyCatalog',
    'VendorCentralTrim',
    'VendorDisputeManagementApp',
    'VendorDisputeManagementLibertyApp',
    'VendorDisputeMonsApp',
    'VendorDownloadDashboard',
    'VendorEconomicsVCLibertyApps',
    'VendorInvoiceCreationLibertyApp',
    'VendorInvoiceManagementLibertyApp',
    'VendorItemAvailabilityMonsWebsite',
    'VendorLedgerReportingPOCArestWeb',
    'VendorLiquidationInvoiceMonsApp',
    'VendorMobileApp',
    'VendorMobileCaselog',
    'VendorOnboardingGuide',
    'VendorOperationHoursPage',
    'VendorOpportunityDashboard',
    'VendorPerformanceDashboard',
    'VendorPrepInstructionDashboard',
    'VendorReceivablesVCSantanaApp',
    'VendorRemittanceLibertyApp',
    'VendorReturnsBulkDownloadMonsKatal',
    'VendorReturnShipmentVisibility',
    'VendorReturnsLibertyApp',
    'VendorReturnsMonsApp',
    'VendorScorecard',
    'VendorSubmissionsMonsApp',
    'VendorTaxRegistrationLibertyApp',
    'VendorUserManagementHz',
    'VendorWebinar',
    'VHDPhase2',
    'Vibes',
    'VineVCHz',
    'VineVendorCentralApplication',
    'VlwMonsApp',
    'VSSCaseManagementLibertyHz',
    'VSSCatalogFeedsWebUI',
    'VSSCouponsLibertyHz',
    'VSSItemCatalogLiberty',
    'VSSItemHz',
    'VSSLibertyOnboardingHz',
    'VSSPicsTradeoffWebsite',
    'VSSPromotionsLibertyApp',
    'VSSSupportManagementLibertyHz',
    'VXReportService',
    'WarehouseTaxSettings',
    'WDVendorCentralAppsLiberty',
];
