<template>
    <div v-if="label" class="toast-root" @click="dismissToast">
        {{ label }}
        <KatIcon class="toast-dismiss" name="close" size="small" />
    </div>
</template>

<script>
import KatIcon from '../katal/KatIcon.vue';
import { mapActions, mapState } from 'pinia';
import { useToastStore } from '../../stores/toastStore';

export default {
    components: {
        KatIcon,
    },
    computed: {
        ...mapState(useToastStore, ['label']),
    },
    methods: {
        ...mapActions(useToastStore, ['dismissToast']),
    },
};
</script>

<style lang="scss" scoped>
.toast-root {
    align-items: center;
    display: flex;
    background-color: rgb(0 0 0 / 65%);
    border-radius: 4px;
    color: $kat-white;
    cursor: pointer;
    position: fixed;
    padding: 4px 8px;
    left: 50%;
    top: 56px;
    font-size: 13px;
    line-height: 18px;
    transform: translateX(-50%);
    z-index: $nav-toast-index;
}

.toast-dismiss {
    filter: invert(1);
    margin-left: 8px;
}
</style>
