<template>
    <div class="kat-spinner-root">
        <!-- Each child div is a quarter circle which spins with a different delay -->
        <div />
        <div />
        <div />
        <div />
    </div>
</template>

<style lang="scss" scoped>
.kat-spinner-root {
    height: 32px;
    position: relative;
    width: 32px;

    div {
        animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border: 4px solid transparent;
        border-radius: 50%;
        border-top-color: $kat-squid-ink-700;
        box-sizing: border-box;
        height: 20px;
        margin: 6px;
        position: absolute;
        width: 20px;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
