<template>
    <div
        data-test-tag="flyout-item"
        ref="root"
        @focusin="isFocused = true"
        @focusout="onFocusOut"
        @mouseleave="isHovered = false"
        @mouseover="isHovered = true"
    >
        <a
            class="flyout-menu-item-container"
            :href="addReftag(navItem.url, navItem.ref, 'subNav')"
            @click.prevent="$emit('click-menu-item')"
            @keyup.enter.prevent="$emit('click-menu-item')"
            tabindex="0"
        >
            <div class="flyout-menu-item-nav-link">
                <span class="flyout-menu-item-label">{{ navItem.text }}</span>
                <div
                    class="flyout-menu-item-external-link"
                    v-if="navItem.dataAttributes && navItem.dataAttributes.isExternalUri"
                    :aria-label="$t('sideNav_alt_externalLink')"
                    role="img"
                >
                    <KatIcon class="flyout-menu-item-external-link-icon" name="launch" size="tiny" />
                </div>
            </div>
            <SideNavBadge class="flyout-menu-item-badge" :nav-item="navItem" />
            <FavoritePageButton
                class="flyout-menu-item-favorite-page-button"
                :nav-item="navItem"
                :is-focused="isFocused"
                :is-hovered="isHovered"
            />
        </a>
    </div>
</template>

<script>
import { addReftag } from '../../util/reftag';
import KatIcon from '../katal/KatIcon.vue';
import SideNavBadge from './SideNavBadge.vue';
import FavoritePageButton from './FavoritePageButton.vue';

export default {
    name: 'FlyoutMenuItem',
    components: { FavoritePageButton, SideNavBadge, KatIcon },
    props: {
        navItem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            observer: null,
            isFocused: false,
            isHovered: false,
        };
    },
    methods: {
        addReftag,
        onFocusOut() {
            // Check if the browser has moved the focus outside of this component
            setTimeout(() => {
                if (!(this.$refs.root && this.$refs.root.contains(document.activeElement))) {
                    this.isFocused = false;
                }
            }, 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.flyout-menu-item {
    &-container {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        padding: 8px 14px;
        text-decoration: none;

        &:hover {
            background-color: $kat-squid-ink-50;
        }

        &:focus,
        &:active:not(:focus-within) {
            background-color: transparent;
            box-shadow: inset 0 0 0 1px $kat-aqua-400;
            outline: none;
        }
    }

    &-nav-link {
        align-content: center;
        color: $kat-squid-ink-700;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        line-height: 24px;
    }

    &-label {
        margin-left: 14px;
    }

    &-external-link {
        align-self: center;
        display: flex;
        margin-left: 4px;

        &-icon {
            filter: invert(13%) sepia(43%) saturate(556%) hue-rotate(172deg) brightness(92%) contrast(86%); // Using filter to recolor the SVG to $kat-squid-ink-700, see https://stackoverflow.com/a/59411103
        }
    }

    &-badge {
        flex: 0 0 auto;
        margin-left: 4px;
    }

    &-favorite-page-button {
        box-sizing: content-box;
        flex: 0 0 auto;
        height: 14px;
        padding: 5px;
        margin-left: 4px;
        width: 14px;

        :deep(img) {
            filter: invert(13%) sepia(43%) saturate(556%) hue-rotate(172deg) brightness(92%) contrast(86%); // Using filter to recolor the SVG to $kat-squid-ink-700, see https://stackoverflow.com/a/59411103
        }
    }
}
</style>
