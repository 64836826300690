import { defineStore } from 'pinia';
import { LoadingStates } from '../enums/LoadingStates';
import { getWithMetrics, postWithMetrics } from '../util/fetchWithMetrics';
import getMetricsPublisher from '../util/katalMetricsPublisher';

const FAVORITES_HIDDEN_STORAGE_KEY = 'vendorCentral_favoritesBar_hidden';

export const useFavoritePagesStore = defineStore('favoritePages', {
    state: () => ({
        favoritePages: [],
        hidden: localStorage.getItem(FAVORITES_HIDDEN_STORAGE_KEY) !== 'false',
        loadingState: LoadingStates.notLoaded,
        showSettingsMenuFlyout: false,
    }),
    getters: {
        getIsFavoritePage(state) {
            return (pageId) => state.favoritePages.findIndex((page) => page.pageId === pageId) !== -1;
        },
    },
    actions: {
        async loadFavoritePages() {
            if (this.loadingState !== LoadingStates.notLoaded) {
                return;
            }
            this.loadingState = LoadingStates.loading;
            try {
                const response = await getWithMetrics('/trim/json/favorites', {}, (payload) => {
                    return (
                        payload &&
                        payload.favoritePages &&
                        Array.isArray(payload.favoritePages.favoritePages) &&
                        typeof payload.favoritePages.hidden === 'boolean'
                    );
                });
                setLoaded(this, response.favoritePages);
            } catch (e) {
                console.error('Failed to load the favorite pages:', e);
                this.loadingState = LoadingStates.error;
            }
        },
        async hideFavoritePages() {
            getMetricsPublisher()
                .newChildActionPublisherForMethod('FavoritePagesHide')
                .publishCounterMonitor('click', 1);
            setHidden(this, true);
            this.showSettingsMenuFlyout = true;
            try {
                await postWithMetrics('/trim/json/favorites/hide');
            } catch (e) {
                console.error('Failed to hide the favorite pages:', e);
            }
        },
        async showFavoritePages(fromNav = false) {
            getMetricsPublisher()
                .newChildActionPublisherForMethod(
                    fromNav ? 'FavoritePagesShowFromNav' : 'FavoritePagesShowFromSettings'
                )
                .publishCounterMonitor('click', 1);
            setHidden(this, false);
            try {
                await postWithMetrics('/trim/json/favorites/show');
            } catch (e) {
                console.error('Failed to show the favorite pages:', e);
            }
        },
        async addFavoritePage(page) {
            const publisher = getMetricsPublisher().newChildActionPublisherForMethod('FavoritePagesAddPage');
            publisher.publishCounterMonitor('click', 1);
            publisher.publishCounterMonitor(`page-${page.pageId}`, 1);
            this.favoritePages = [...this.favoritePages, page];
            try {
                await postWithMetrics('/trim/json/favorites/add-page', { pageId: page.pageId });
            } catch (e) {
                console.error(`Failed to add '${page.pageId}' to the favorite pages`, e);
            }
            if (this.hidden) {
                await this.showFavoritePages(true);
            }
        },
        async removeFavoritePage(pageId) {
            const publisher = getMetricsPublisher().newChildActionPublisherForMethod('FavoritePagesRemovePageFromNav');
            publisher.publishCounterMonitor('click', 1);
            publisher.publishCounterMonitor(`page-${pageId}`, 1);
            this.favoritePages = this.favoritePages.filter((page) => page.pageId !== pageId);
            try {
                await postWithMetrics('/trim/json/favorites/remove-page', { pageId });
            } catch (e) {
                console.error(`Failed to remove '${pageId}' from the favorite pages`, e);
            }
        },
        async editFavoritePages(favoritePages) {
            const publisher = getMetricsPublisher().newChildActionPublisherForMethod('FavoritePagesSaveEdit');
            publisher.publishCounterMonitor('click', 1);
            let keptPageCount = 0;
            for (const page of favoritePages) {
                publisher.publishString(`keptPage-${keptPageCount++}`, page.pageId);
            }
            const keptPageIds = new Set(favoritePages.map((page) => page.pageId));
            let removedPageCount = 0;
            for (const page of this.favoritePages) {
                if (!keptPageIds.has(page.pageId)) {
                    publisher.publishString(`removedPage-${removedPageCount++}`, page.pageId);
                }
            }
            setLoaded(this, { favoritePages, hidden: false });
            try {
                const pageIds = favoritePages.map((page) => page.pageId);
                await postWithMetrics('/trim/json/favorites/edit-pages', { pageIds });
            } catch (e) {
                console.error('Failed to edit the favorite pages', e);
            }
        },
        closeSettingsMenuFlyout() {
            this.showSettingsMenuFlyout = false;
        },
    },
});

const setLoaded = (store, { favoritePages, hidden }) => {
    store.favoritePages = favoritePages ? favoritePages : [];
    setHidden(store, hidden);
    store.loadingState = LoadingStates.loaded;
};

const setHidden = (store, hidden) => {
    store.hidden = hidden;
    localStorage.setItem(FAVORITES_HIDDEN_STORAGE_KEY, hidden ? 'true' : 'false');
};
