<template>
    <div
        :style="getFlyoutStyles"
        class="flyout-menu"
        data-test-tag="flyout-container"
        :aria-expanded="isVisible"
        ref="root"
    >
        <div class="flyout-menu-triangle-with-shadow" :style="getTriangleStyles" />
        <!-- An L2 can be a label for a group of L3 nav items, or be a nav item in its own right. -->
        <template v-for="(l2Item, index) in navTab.subNav || []">
            <template v-if="(l2Item.subNav || []).length > 0">
                <div class="flyout-menu-group-label">
                    {{ l2Item.text }}
                </div>
                <FlyoutMenuItem
                    v-for="l3Item in l2Item.subNav"
                    :nav-item="l3Item"
                    :key="l3Item.menuId"
                    @click-menu-item="onClickMenuItem(l3Item)"
                />
            </template>
            <template v-else>
                <FlyoutMenuItem
                    :key="l2Item.menuId"
                    :nav-item="l2Item"
                    ref="l2-nav-item"
                    @click-menu-item="onClickMenuItem(l2Item)"
                />
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useDismissalsAndExposuresStore } from '../../stores/dismissalsAndExposuresStore';
import { useSideNavStore } from '../../stores/sideNavStore';
import getAllChildNavItems from '../../util/getAllChildNavItems';
import { addReftag } from '../../util/reftag';
import FlyoutMenuItem from './FlyoutMenuItem.vue';
import Flyout from './Flyout.vue';

export default {
    name: 'FlyoutMenu',
    components: {
        Flyout,
        FlyoutMenuItem,
    },
    props: {
        navTab: {
            type: Object,
            required: true,
        },
        triangleSpacingFromTop: {
            type: Number,
            default: 0,
        },
        navMenuSpacingFromLeft: {
            type: Number,
            default: 0,
        },
        navMenuSpacingFromTop: {
            type: Number,
            default: 0,
        },
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            openTime: null,
        };
    },
    computed: {
        ...mapState(useDismissalsAndExposuresStore, ['isDismissed', 'isExposed']),
        getFlyoutStyles() {
            return {
                [document.dir === 'rtl' ? 'right' : 'left']: `${this.navMenuSpacingFromLeft}px`,
                top: `${this.navMenuSpacingFromTop}px`,
                visibility: this.isVisible ? 'visible' : 'hidden',
            };
        },
        getTriangleStyles() {
            return {
                [document.dir === 'rtl' ? 'right' : 'left']: `${this.navMenuSpacingFromLeft - 40 + 1}px`, // Offset the size of the triangle and 1px to cover the border
                top: `${this.triangleSpacingFromTop}px`,
            };
        },
    },
    methods: {
        ...mapActions(useDismissalsAndExposuresStore, ['addDismissal', 'addExposure']),
        ...mapActions(useSideNavStore, ['closeSideNav']),
        onClickMenuItem(navItem) {
            if (
                navItem.menuId &&
                navItem.dataAttributes &&
                navItem.dataAttributes.badgeType &&
                !this.isDismissed(navItem.menuId)
            ) {
                this.addDismissal(navItem.menuId);
            }
            if (this.openTime) {
                const openDuration = Date.now() - this.openTime;
                this.$metrics
                    .newChildActionPublisherForMethod('RedesignImpression')
                    .publishTimerMonitor(`sc-navtab-${this.navTab.menuId}.page-${navItem.menuId}.click`, openDuration);
                this.openTime = null; // Prevent the 'show' metric from also being logged
            }
            window.sessionStorage.setItem('navmetric-linked-from', `sc-navtab-${this.navTab.menuId}=${window.ue_id}`);
            this.closeSideNav();
            window.location.href = addReftag(navItem.url, navItem.ref, 'subNav');
        },
        opened() {
            this.openTime = Date.now();
            const childNavItems = getAllChildNavItems(this.navTab);
            for (const childNavItem of childNavItems) {
                if (
                    childNavItem.dataAttributes &&
                    childNavItem.dataAttributes.badgeType &&
                    !this.isExposed(childNavItem.menuId)
                ) {
                    this.addExposure(childNavItem.menuId);
                }
            }
        },
        closed() {
            if (this.openTime) {
                const openDuration = Date.now() - this.openTime;
                if (openDuration > 100) {
                    this.$metrics
                        .newChildActionPublisherForMethod('RedesignImpression')
                        .publishTimerMonitor(`sc-navtab-${this.navTab.menuId}.show`, openDuration);
                }
                this.openTime = null;
            }
        },
    },
    watch: {
        isVisible() {
            if (this.isVisible) {
                this.opened();
            } else {
                this.closed();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.flyout-menu {
    border-left: 1px solid rgb(0 0 0 / 20%);
    background-color: white;
    cursor: default;
    position: fixed;
    max-height: 100vh;
    overflow-y: auto;
    transition: 4ms;
    width: calc(min(300px, 50vw)); // On mobile, it should take up only half of the screen

    &-triangle-with-shadow {
        height: 40px;
        position: fixed;
        overflow: hidden;
        width: 40px;

        &::after {
            box-shadow: -1px 5px 9px -5px rgb(0 0 0 / 50%);
            background: white;
            content: '';
            position: absolute;
            height: 15px;
            left: 35px;
            transform: rotate(45deg);
            top: 14px;
            width: 15px;
        }
    }

    &-group-label {
        border-bottom: 1px solid $athens;
        color: $kat-squid-ink-700;
        font-size: 12px;
        font-weight: $font-weight-bold;
        line-height: 15px;
        margin: 14px 22px 7px;
        padding-bottom: 4px;
    }
}
</style>
