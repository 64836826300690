import getMetricsPublisher from './katalMetricsPublisher';

export default function initNavConversionMetrics() {
    const linkedFrom = window.sessionStorage.getItem('nav-linked-from');
    if (!linkedFrom) {
        // The user didn't navigate to the current page by clicking on a
        // navigation item.
        return;
    }
    window.sessionStorage.removeItem('nav-linked-from');
    const [tabId, actionId] = linkedFrom.split('=');
    const metricsPublisher = getMetricsPublisher();
    metricsPublisher
        .newChildActionPublisherForMethod('RedesignNavigation')
        .publishStringTruncate(`linked_from.${tabId}`, actionId);
    const bodyContent = document.getElementById('sc-content-container');
    if (!bodyContent) {
        return;
    }
    let startTime = Date.now();
    const logInteraction = (event) => {
        if (!startTime) {
            return;
        }
        metricsPublisher
            .newChildActionPublisherForMethod('RedesignInteraction')
            .publishTimerMonitor(
                event.type === 'click' ? `click.${tabId}` : `keyboard.${tabId}`,
                Date.now() - startTime
            );
        // We only want to log a single interaction event, regardless of type
        startTime = null;
    };
    for (const eventType of ['click', 'keyup']) {
        bodyContent.addEventListener(eventType, logInteraction, { once: true });
    }
}
