import { defineStore } from 'pinia';
import { LoadingStates } from '../enums/LoadingStates';
import { getWithMetrics, postWithMetrics } from '../util/fetchWithMetrics';
import getMetricsPublisher from '../util/katalMetricsPublisher';

const MILLISECONDS_TO_EXPIRE = 1000 * 60 * 60 * 24 * 7; // 1 week

export const useDismissalsAndExposuresStore = defineStore('dismissalsAndExposures', {
    state() {
        return {
            dismissals: [],
            exposures: new Map(),
            loadingState: LoadingStates.notLoaded,
        };
    },
    getters: {
        isDismissed(state) {
            return (navItemId) => state.dismissals.includes(navItemId);
        },
        isExposed(state) {
            return (navItemId) => state.exposures.has(navItemId);
        },
        isExpired(state) {
            return (navItemId) =>
                state.exposures.has(navItemId) &&
                Date.now() >= new Date(state.exposures.get(navItemId)).getTime() + MILLISECONDS_TO_EXPIRE;
        },
        isLoaded(state) {
            return () => state.loadingState === LoadingStates.loaded;
        },
    },
    actions: {
        async loadDismissalsAndExposures() {
            if (this.loadingState !== LoadingStates.notLoaded) {
                return;
            }
            this.loadingState = LoadingStates.loading;
            try {
                const payload = await getWithMetrics(
                    '/trim/json/dismissals-and-exposures',
                    {},
                    (payload) => Array.isArray(payload.dismissals) && payload.exposures instanceof Object
                );
                this.dismissals = payload.dismissals;
                this.exposures = new Map(Object.entries(payload.exposures));
                this.loadingState = LoadingStates.loaded;
            } catch (e) {
                console.error('Failed to load navigation dismissals and exposures', e);
                this.loadingState = LoadingStates.error;
            }
        },
        async addDismissal(navItemId) {
            if (this.loadingState !== LoadingStates.loaded) {
                return;
            }
            const publisher = getMetricsPublisher().newChildActionPublisherForMethod('AddDismissal');
            publisher.publishCounterMonitor('click', 1);
            publisher.publishCounterMonitor(navItemId, 1);
            this.dismissals = [...this.dismissals, navItemId];
            try {
                await postWithMetrics('/trim/json/add-dismissal', { navItemId: navItemId });
            } catch (e) {
                console.error(`Failed to add '${navItemId}' to navigation dismissals`, e);
            }
        },
        async addExposure(navItemId) {
            if (this.loadingState !== LoadingStates.loaded) {
                return;
            }
            const publisher = getMetricsPublisher().newChildActionPublisherForMethod('AddExposure');
            publisher.publishCounterMonitor('click', 1);
            publisher.publishCounterMonitor(navItemId, 1);
            this.exposures.set(navItemId, new Date().toISOString());
            try {
                await postWithMetrics('/trim/json/add-exposure', { navItemId: navItemId });
            } catch (e) {
                console.error(`Failed to add '${navItemId}' to navigation exposures`, e);
            }
        },
    },
});
