import { defineStore } from 'pinia';

export const useWindowStore = defineStore('window', {
    state() {
        return {
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
        };
    },
    actions: {
        initializeResizeListener() {
            window.addEventListener('resize', this.updateWindowSize);
        },
        updateWindowSize() {
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
        },
    },
});
