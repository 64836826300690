<template>
    <div class="utility-bar">
        <Flyout
            data-test-tag="settings-list-flyout"
            :disable-trigger="showFavoritesBarSettingsMenuFlyout"
            :force-open="showFavoritesBarSettingsMenuFlyout"
            :trigger-aria-label="$t('header_utilityBar_alt_settings')"
            @close="onCloseSettingsMenu"
        >
            <template #trigger>
                <div class="utility-bar-settings">
                    <KatIcon class="utility-bar-icon" data-test-tag="settings-menu-trigger" name="gear" size="small" />
                </div>
            </template>
            <template v-slot:default="slotProps">
                <div
                    v-if="showFavoritesBarSettingsMenuFlyout"
                    class="utility-bar-favorite-pages-flyout"
                    @click="slotProps.closeFlyout"
                >
                    {{ $t('favoritePages_flyout_showFavoritesBarInstructions') }}
                </div>
                <TheSettingsList v-else :close-flyout="slotProps.closeFlyout" />
            </template>
        </Flyout>
        <Flyout v-if="showLocaleList" :trigger-aria-label="$t('header_utilityBar_alt_language')">
            <template #trigger>
                <div class="utility-bar-locale" data-test-tag="locale-list-trigger">
                    <div class="utility-bar-locale-icon-wrapper">
                        {{ languageCode }}
                        <KatIcon class="utility-bar-icon" name="arrow_drop_down" size="small" />
                    </div>
                </div>
            </template>
            <template v-slot:default="slotProps">
                <TheLocaleList :close-flyout="slotProps.closeFlyout" :locales="languages" />
            </template>
        </Flyout>
        <div class="utility-bar-help">
            <a
                class="utility-bar-button-link"
                :href="helpUrl"
                @click="onClickHelpLink"
                tabindex="0"
                :aria-label="$t('header_utilityBar_alt_help')"
                data-test-tag="utility-bar-help-link"
            >
                <span v-if="windowWidth >= KatalBreakpoints.MEDIUM">
                    {{ $t('header_utilityBar_alt_help') }}
                </span>
                <KatIcon v-else class="utility-bar-icon" name="help_outline" size="small" />
            </a>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import KatalBreakpoints from '../../constants/KatalBreakpoints';
import { HELP_URL, HELP_URL_LEGACY } from '../../constants/urls';
import { Site } from '../../enums/Site';
import { useContextStore } from '../../stores/contextStore';
import { useLanguagesStore } from '../../stores/languagesStore';
import { useWindowStore } from '../../stores/windowStore';
import KatIcon from '../katal/KatIcon.vue';
import Flyout from './Flyout.vue';
import TheLocaleList from './TheLocaleList.vue';
import TheSettingsList from './TheSettingsList.vue';
import { useFavoritePagesStore } from '../../stores/favoritePagesStore';

export default {
    name: 'TheUtilityBar',
    components: {
        TheLocaleList,
        Flyout,
        KatIcon,
        TheSettingsList,
    },
    computed: {
        ...mapState(useContextStore, ['site']),
        ...mapState(useLanguagesStore, ['languages']),
        ...mapState(useWindowStore, ['windowWidth']),
        ...mapState(useFavoritePagesStore, { showFavoritesBarSettingsMenuFlyout: 'showSettingsMenuFlyout' }),
        helpUrl() {
            return this.site === Site.VENDOR_CENTRAL ? HELP_URL : HELP_URL_LEGACY;
        },
        languageCode() {
            return this.$locale.slice(0, 2).toUpperCase();
        },
        KatalBreakpoints() {
            return KatalBreakpoints;
        },
        showLocaleList() {
            return this.site !== Site.ADVANTAGE && this.languages.length > 1;
        },
    },
    methods: {
        ...mapActions(useFavoritePagesStore, ['closeSettingsMenuFlyout']),
        onClickHelpLink() {
            this.$metrics.newChildActionPublisherForMethod('HelpLinkClick').publishCounterMonitor('click', 1);
        },
        onCloseSettingsMenu() {
            if (this.showFavoritesBarSettingsMenuFlyout) {
                this.closeSettingsMenuFlyout();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$icon-margin-sides: 9px;

.utility-bar {
    align-items: center;
    display: flex;
    flex-direction: row;

    > div {
        display: flex;
        margin: 0 $icon-margin-sides;

        &:nth-of-type(1) {
            margin-left: 0;
        }

        &:nth-last-of-type(1) {
            margin-right: 0;
        }
    }

    &-button-link {
        color: white;
        display: flex;
        position: relative;
        text-decoration: none;
    }

    &-help {
        cursor: pointer;
    }

    &-icon {
        filter: invert(1); // Makes the icon white
    }

    &-locale {
        align-items: center;
        color: white;
        cursor: default;
        display: flex;

        &-icon-wrapper {
            align-items: center;
            display: flex;
            margin-right: -5px;
        }
    }

    &-settings {
        height: 20px;
        width: 20px;
    }

    &-favorite-pages-flyout {
        padding: 10px;
        width: 160px;
        color: $kat-squid-ink-700;
    }
}
</style>
