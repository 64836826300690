// This converts the translation file from the PUFF-J format which Panther emits to the one which vue-i18n expects.
function convertPuffJToVueI18n(translations) {
    const fixed = {};
    for (const [k, v] of Object.entries(translations)) {
        if (typeof v === 'object') {
            if ('value' in v) {
                fixed[k] = v['value'];
            }
        } else if (typeof v === 'string') {
            fixed[k] = v;
        }
    }
    return fixed;
}
module.exports = convertPuffJToVueI18n;
