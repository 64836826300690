import { defineStore } from 'pinia';
import { LoadingStates } from '../enums/LoadingStates';
import { getWithMetrics } from '../util/fetchWithMetrics';

const REQUEST_ID_META_TAG = 'a2z:request_id';
const SITE_META_TAG = 'a2z:mons_site_name';

export const useContextStore = defineStore('context', {
    state: () => ({
        loadingState: LoadingStates.notLoaded,
        errorMessage: '',
        site: undefined,
        customerId: undefined,
        vendorGroup: undefined,
        vendorCodes: [],
        marketscope: undefined,
        businessName: undefined,
        requestId: undefined,
    }),
    getters: {
        isAuthorized() {
            return this.loadingState === LoadingStates.loaded && this.vendorGroup != null;
        },
    },
    actions: {
        async loadContext() {
            if (this.loadingState !== LoadingStates.notLoaded) {
                return;
            }
            this.loadingState = LoadingStates.loading;
            this.requestId = getMonsMetaTag(REQUEST_ID_META_TAG);
            this.site = getMonsMetaTag(SITE_META_TAG);
            try {
                const output = await getWithMetrics('/trim-metadata/context', {}, (payload) => true);
                this.customerId = output.customerId;
                this.vendorGroup = output.vendorGroup;
                this.marketscope = output.marketscope;
                this.businessName = output.businessName;
                this.vendorCodes = output.vendorCodes;
                this.loadingState = LoadingStates.loaded;
            } catch (error) {
                this.errorMessage = `Error encountered while calling GetMonsContext: [${error}]`;
                this.loadingState = LoadingStates.error;
            }
        },
    },
});

function getMonsMetaTag(name) {
    return document?.querySelector(`[name="${name}"]`)?.getAttribute('content');
}
