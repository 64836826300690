import { defineStore } from 'pinia';
import { LoadingStates } from '../enums/LoadingStates';
import { VendorGroupStatus } from '../enums/VendorGroupStatus';
import { getWithMetrics } from '../util/fetchWithMetrics';

export const useVendorStatusStore = defineStore('vendorStatus', {
    state: () => ({
        loadingState: LoadingStates.notLoaded,
        errorMessage: '',
        vendorStatus: null,
    }),
    getters: {
        isInSubmitted() {
            return this.loadingState === LoadingStates.loaded && this.vendorStatus === VendorGroupStatus.submitted;
        },
    },
    actions: {
        async loadVendorStatus() {
            if (this.loadingState !== LoadingStates.notLoaded) {
                return;
            }
            this.errorMessage = '';
            this.loadingState = LoadingStates.loading;

            try {
                const payload = await getWithMetrics('/trim-metadata/vendor-status', {}, (payload) => payload.status);
                this.vendorStatus = payload.status;
                this.loadingState = LoadingStates.loaded;
            } catch (error) {
                this.errorMessage = `Error encountered while fetching vendor status: [${error}]`;
                this.loadingState = LoadingStates.error;
            }
        },
    },
});
