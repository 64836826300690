<template>
    <!-- More information about GAPS here: https://w.amazon.com/bin/view/Pumas-team/OurOfferings/AccountSwitchers -->
    <div
        id="dropdown-account-switcher-container"
        data-mode="regionalOnly"
        data-nature="vendorGroup"
        :data-regional-account="regionalAccount && JSON.stringify(regionalAccount)"
        :data-regional-account-loading-state="loadingState"
        data-test-tag="dropdown-account-switcher-container"
    >
        <a
            class="account-switcher-fallback"
            href="/account-switcher/regional/vendorGroup"
            data-test-tag="account-switcher-fallback"
        >
            {{ $t('header_accountSwitcher_switchAccounts') }}
        </a>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { LoadingStates } from '../enums/LoadingStates';
import { useRegionalAccountStore } from '../stores/regionalAccountStore';

export default {
    name: 'AccountSwitcherLoader',
    computed: {
        ...mapState(useRegionalAccountStore, ['loadingState', 'regionalAccount']),
    },
    methods: {
        appendScriptsToDom() {
            const idPrefix = 'dropdown-account-switcher-loader';
            const scriptEl = document.createElement('script');
            scriptEl.id = `${idPrefix}-script`;
            scriptEl.src = '/account-switcher/dropdown-assets-loader.js';
            scriptEl.setAttribute('data-test-tag', `${idPrefix}-script`);
            document.head.appendChild(scriptEl);
        },
    },
    mounted() {
        useRegionalAccountStore().loadRegionalAccount();
        this.appendScriptsToDom();
    },
};
</script>

<style lang="scss" scoped>
#dropdown-account-switcher-container {
    align-items: center;
    display: flex;
    height: 48px;
}

.account-switcher-fallback {
    font-size: 12px;
    line-height: 16px;
}
</style>
