export default function getAllChildNavItems(navItem) {
    if (!navItem || !navItem.subNav) {
        return [];
    }
    const result = [];
    const queue = [...navItem.subNav];
    while (queue.length > 0) {
        const childNavItem = queue.shift();
        result.push(childNavItem);
        if (childNavItem.subNav) {
            queue.push(...childNavItem.subNav);
        }
    }
    return result;
}
