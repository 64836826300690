import { defineStore } from 'pinia';

export const useSideNavStore = defineStore('sideNav', {
    state() {
        return {
            isOpen: false,
        };
    },
    actions: {
        openSideNav() {
            this.isOpen = true;
        },
        closeSideNav() {
            this.isOpen = false;
        },
    },
});
