<template>
    <a
        :href="addReftag(uri, reftag, 'subNav')"
        class="flyout-menu-item-container"
        :data-test-tag="`settings-menu-item-${menuId}`"
    >
        {{ label }}
    </a>
</template>

<script>
import { addReftag } from '../../util/reftag';

export default {
    methods: { addReftag },
    props: {
        menuId: String,
        label: String,
        uri: String,
        reftag: {
            type: String,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.flyout-menu-item-container {
    color: $kat-squid-ink-700;
    cursor: pointer; // Override conflicting CSS style
    display: flex;
    flex-direction: row;
    padding: $settings-list-vert-padding $settings-list-horiz-padding;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        background-color: $kat-squid-ink-50;
        outline: none;
    }

    &:active,
    &:focus {
        outline: $default-outline $kat-aqua-400;
    }
}
</style>
