<template>
    <div id="vc-header" :class="inHeaderMarginAllowList ? 'vc-header' : ''">
        <TheNavbar />
        <FavoritePagesBar />
        <SideNav />
        <Toast />
    </div>
</template>

<script>
import SideNav from './SideNav.vue';
import TheNavbar from './TheNavbar.vue';
import Toast from './Toast.vue';
import FavoritePagesBar from './FavoritePagesBar.vue';
import { headerMarginMonsAppAllowList } from '../../util/headerMarginAllowList.js';
export default {
    name: 'TheHeader',
    components: { Toast, SideNav, TheNavbar, FavoritePagesBar },
    computed: {
        inHeaderMarginAllowList() {
            const monsAppName = document.head.querySelector('meta[name="a2z:mons_app_name"]')?.content;
            return monsAppName && headerMarginMonsAppAllowList.includes(monsAppName);
        },
    },
};
</script>

<style lang="scss" scoped>
#vc-header {
    background-color: $kat-squid-ink-700;
    color: white;
    font-family: $font-family-base;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
}

.vc-header {
    margin-bottom: 30px;
}
</style>
