import KatalMetricHttpRequest from '@amzn/katal-metrics/lib/metricObject/KatalMetricHttpRequest';
import getMetricsPublisher from './katalMetricsPublisher';

/**
 * Creates a metric name that corresponds to an AJAX call.
 *
 * @param path A relative path for the AJAX call, e.g. "/trim/json/nav"
 * @return {string} e.g. "trim.json.nav"
 */
function getPathMetricName(path) {
    return (path || '').replace(/^\//, '').replace(/\//g, '.');
}

/**
 * Gets data from an AJAX endpoint and logs metrics data.
 *
 * @param path A relative path for the AJAX call
 * @param searchParams A map of URL search parameters
 * @param validatePayload A function that validates the payload in the AJAX response
 * @return {Promise<Object>}
 */
export async function getWithMetrics(path, searchParams, validatePayload) {
    const publisher = getMetricsPublisher().newChildActionPublisherForMethod('AjaxRequests');
    const httpMetrics = new KatalMetricHttpRequest(getPathMetricName(path)).withMonitor(true);
    try {
        const response = await fetch(getURL(path, searchParams), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        });
        const payload = await response.json();
        if (!response.ok) {
            const errorMessage = (payload && payload.message) || response.statusText;
            throw new Error(errorMessage);
        }
        if (!validatePayload(payload)) {
            throw new Error(`Payload failed validation: ${JSON.stringify(payload)}`);
        }
        httpMetrics.setSuccess();
        return payload;
    } catch (e) {
        httpMetrics.setFailure();
        console.error(`Failed to GET from '${path}': `, e);
        throw e;
    } finally {
        publisher.publish(httpMetrics);
    }
}

/**
 * Posts data to an AJAX endpoint and logs metrics data.
 *
 * @param path A relative path for the AJAX call
 * @param body The data to be sent in the request
 */
export async function postWithMetrics(path, body) {
    const publisher = getMetricsPublisher().newChildActionPublisherForMethod('AjaxRequests');
    const httpMetrics = new KatalMetricHttpRequest(getPathMetricName(path)).withMonitor(true);
    try {
        const response = await fetch(path, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        const payload = await response.json();
        if (!response.ok) {
            const errorMessage = (payload && payload.message) || response.statusText;
            throw new Error(errorMessage);
        }
        httpMetrics.setSuccess();
    } catch (e) {
        httpMetrics.setFailure();
        console.error(`Failed to POST to '${path}': `, e);
        throw e;
    } finally {
        publisher.publish(httpMetrics);
    }
}

function getURL(path, searchParams) {
    if (!searchParams) {
        return path;
    }
    const fixedSearchParams = {};
    for (const [k, v] of Object.entries(searchParams)) {
        if (v === null || v === undefined) {
            fixedSearchParams[k] = ''; // Prevent "null" and "undefined" from showing up in the URL
        } else {
            fixedSearchParams[k] = v;
        }
    }
    return `${path}?${new URLSearchParams(fixedSearchParams)}`;
}
