<template>
    <img :class="{ [size]: true, 'mirror-rtl': shouldMirrorRtl }" :src="iconSrc" aria-hidden="true" />
</template>

<script>
import arrowDropDownSvg from '../../../images/icons/arrow_drop_down.svg';
import checkSvg from '../../../images/icons/check.svg';
import chevronDownSvg from '../../../images/icons/chevron-down.svg';
import chevronRightSvg from '../../../images/icons/chevron-right.svg';
import clearSvg from '../../../images/icons/clear.svg';
import closeSvg from '../../../images/icons/close.svg';
import gearSvg from '../../../images/icons/gear.svg';
import hamburgerSvg from '../../../images/icons/hamburger.svg';
import helpOutlineSvg from '../../../images/icons/help_outline.svg';
import launchSvg from '../../../images/icons/launch.svg';
import searchSvg from '../../../images/icons/search.svg';
import bookmarkSvg from '../../../images/icons/bookmark.svg';
import bookmarkBorderSvg from '../../../images/icons/bookmark_border.svg';
import draggableSvg from '../../../images/icons/draggable.svg';
import infoCircleFillSvg from '../../../images/icons/info-circle-fill.svg';
import moreHorizSvg from '../../../images/icons/more_horiz.svg';

/**
 * Based on kat-icon: https://katal.amazon.dev/components/icon/. To add a new
 * icon, download the SVG file from https://code.amazon.com/packages/KatalComponents/trees/mainline/--/public/icons
 * and put it in /src/images/icons.
 *
 * Note, one annoying consequence of using the img tag is that it's not possible
 * to change the color of the SVG using 'fill'. Instead, you can use 'filter' as
 * described in https://stackoverflow.com/a/59411103.
 */
export default {
    name: 'KatIcon',
    props: {
        name: String,
        size: {
            type: String,
            default: 'small',
        },
    },
    computed: {
        iconSrc() {
            switch (this.name) {
                case 'arrow_drop_down':
                    return arrowDropDownSvg;
                case 'check':
                    return checkSvg;
                case 'chevron-down':
                    return chevronDownSvg;
                case 'chevron-right':
                    return chevronRightSvg;
                case 'clear':
                    return clearSvg;
                case 'close':
                    return closeSvg;
                case 'gear':
                    return gearSvg;
                case 'hamburger':
                    return hamburgerSvg;
                case 'help_outline':
                    return helpOutlineSvg;
                case 'launch':
                    return launchSvg;
                case 'search':
                    return searchSvg;
                case 'bookmark':
                    return bookmarkSvg;
                case 'bookmark_border':
                    return bookmarkBorderSvg;
                case 'draggable':
                    return draggableSvg;
                case 'info-circle-fill':
                    return infoCircleFillSvg;
                case 'more_horiz':
                    return moreHorizSvg;
                default:
                    throw new Error(`Unknown name '${this.name}' provided`);
            }
        },
        shouldMirrorRtl() {
            return ['chevron-right', 'launch'].includes(this.name);
        },
    },
};
</script>

<style lang="scss" scoped>
.tiny {
    box-sizing: content-box;
    height: 14px;
    vertical-align: unset;
    width: 14px;
}

.small {
    box-sizing: content-box;
    height: 20px;
    vertical-align: unset;
    width: 20px;
}

.large {
    box-sizing: content-box;
    height: 40px;
    vertical-align: unset;
    width: 40px;
}
</style>
