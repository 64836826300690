import { Context, Metric, Publisher } from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsContext from '@amzn/katal-metrics/lib/KatalMetricsContext';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';

const SITE_NAME = 'VendorCentral'; // Katal prefers Pascal case
const SERVICE_NAME = 'VendorCentralTrim';

function createConsoleLogMetricsPublisher() {
    return new Publisher(
        new KatalMetricsDriverConsoleLogJson({
            log: console.table, // Using console.table to preserve data across page navigations
        }),
        console.error,
        new Context.Builder().withSite(SITE_NAME).withServiceName(SERVICE_NAME).build()
    );
}

function getDomain() {
    const sUrl = window.ue_surl;
    if (sUrl) {
        if (sUrl.indexOf('nexusclient.prod') !== -1) {
            return 'prod';
        }
        if (sUrl.indexOf('nexusclient.gamma') !== -1) {
            return 'test';
        }
    }
    console.error('Could not get the domain from ue_surl, falling back to "prod".');
    return 'prod';
}

const SUBDOMAIN_REALM_MAP = {
    na: 'USAmazon',
    eu: 'EUAmazon',
    fe: 'FEAmazon',
    cn: 'CNAmazon',
};

function getRealm() {
    const sUrl = window.ue_surl;
    if (sUrl) {
        const subdomain = sUrl.match(/unagi[-]([a-z]{2})/);
        if (subdomain) {
            return SUBDOMAIN_REALM_MAP[subdomain[1]];
        }
    }
    console.error('Could not get the realm from ue_surl, falling back to "USAmazon".');
    return 'USAmazon';
}

function createDefaultMetricsPublisher() {
    try {
        const errorHandler = console.error;
        const metricsContextBuilder = new KatalMetricsContext.Builder()
            .withSite(SITE_NAME)
            .withServiceName(SERVICE_NAME);
        if (window.ue_id) {
            metricsContextBuilder.withRelatedMetrics(new Metric.String('http_request_id', window.ue_id));
        } else {
            errorHandler('Could not set http_request_id because window.ue_id was missing');
        }
        return new KatalMetricsPublisher(
            new KatalMetricsDriverSushi.Builder()
                .withDomainRealm(getDomain(), getRealm())
                .withErrorHandler(errorHandler)
                .build(),
            errorHandler,
            metricsContextBuilder.build()
        );
    } catch (error) {
        console.error('Could not initialize default metrics publisher. Falling back to console log.', error);
        return createConsoleLogMetricsPublisher();
    }
}

let metricsPublisher; // We want a single metric publisher to be shared across all Vue apps

export default function getMetricsPublisher() {
    if (!metricsPublisher) {
        const header = document.getElementById('vc-header-container');
        const logToConsole = header && header.getAttribute('data-katal-metrics-log-to-console');
        metricsPublisher = logToConsole ? createConsoleLogMetricsPublisher() : createDefaultMetricsPublisher();
    }
    return metricsPublisher;
}
