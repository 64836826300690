<template>
    <div id="hmd2f-container" :class="`a-section fc-left ${!hmdCollapsed ? 'expand' : ''}`">
        <div id="hmd2f-trigger-tab" :class="`hmd2f-${hmdCollapsed ? 'close' : 'active'}`" v-show="!feedbackDismissed">
            <a class="vc-footer-hmd-feedback-link" v-on:click="collapseExpandHMD()">
                {{ $t('footer_howsMyDriving_label') }}
                <KatIcon class="hmd2f-expand-collapse-img" v-show="!hmdCollapsed" name="chevron-down" size="tiny" />
            </a>
            <div
                id="hmd2f-exit"
                class="vc-footer-hmd-feedback-exit"
                v-on:click="feedbackDismissed = true"
                v-show="hmdCollapsed"
            >
                <KatIcon name="clear" size="tiny" />
            </div>
        </div>
        <div id="hmd2f-content-wrap" class="vc-footer-hmd-content-container">
            <div id="hmd-iframe" class="hmd2f-content-inner">
                <iframe
                    id="hmd-iframe-id"
                    ref="iframe"
                    class="vc-footer-hmd-iframe"
                    sandbox="allow-scripts allow-same-origin"
                    :src="iframeURL"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useContextStore } from '../stores/contextStore';
import KatIcon from './katal/KatIcon';

export default {
    name: 'TheHMD',
    components: { KatIcon },
    data: function () {
        return {
            hmdCollapsed: true,
            iframeURL: undefined,
            feedbackDismissed: false,
        };
    },
    computed: {
        ...mapState(useContextStore, ['site', 'requestId']),
        iframeParams() {
            const fromUrl = `fromUrl=${window.location.pathname}`;
            const refRID = `refRID=${this.requestId}`;
            const HMDName = `HMDName=${this.site[0].toUpperCase() + this.site.slice(1)}Feedback`;
            return `${fromUrl}&${refRID}&${HMDName}`;
        },
    },
    created() {
        window.addEventListener('message', (event) => {
            if (event.data === 'hmdDismiss') {
                this.hmdCollapsed = true;
            }
        });
    },
    methods: {
        collapseExpandHMD() {
            // Set iframe src on first time of opening HMD
            this.iframeURL = `/gp/satisfaction/survey-form-frame.html?${this.iframeParams}`;

            this.hmdCollapsed = !this.hmdCollapsed;

            const iframeEl = this.$refs.iframe;
            iframeEl?.contentWindow?.postMessage(this.hmdCollapsed ? 'hmdShown' : 'hmdHidden', '*');
            this.$metrics
                .newChildActionPublisherForMethod('HMD')
                .publishCounterMonitor(this.hmdCollapsed ? 'closed' : 'opened', 1);
        },
    },
};
</script>

<style lang="scss" scoped>
/* stylelint-disable no-descending-specificity -- This file's styles use a lot of nesting */
.vc-footer-hmd-feedback-link,
.vc-footer-hmd-feedback-exit {
    cursor: pointer;
}

.vc-footer-hmd-feedback-exit img {
    filter: invert(1); // Using filter to recolor the SVG to white, see https://stackoverflow.com/a/59411103
    max-width: none; // Overrides style from AUI
}

#hmd-iframe-id {
    width: 100%;
    height: 576px;
}

$hmd-starting-position: -478px;
$hmd-tab-height: 26px;

#hmd2f-container {
    position: fixed;
    bottom: $hmd-starting-position;
    height: 508px;
    z-index: 99;
    margin-bottom: 0;
    transition: bottom 200ms;
    line-height: normal; // Overrides style from AUI

    &.fc-left {
        left: 0;
    }

    &.expand {
        width: 466px;
        z-index: 1501;
        bottom: 0;

        #hmd2f-content-wrap {
            visibility: visible;
        }

        #hmd2f-trigger-tab a {
            margin-right: 12px;
        }
    }

    &.dismissed {
        bottom: $hmd-starting-position - $hmd-tab-height;
        animation: 1s disappear;
        animation-fill-mode: forwards;
    }

    @keyframes disappear {
        99% {
            visibility: visible;
        }

        100% {
            visibility: hidden;
        }
    }
}

/* Feedback trigger tab */
#hmd2f-trigger-tab {
    border-color: rgb(221 221 221 / 100%) rgb(221 221 221 / 100%) -moz-use-text-color;
    border-radius: 6px 6px 0 0;
    border-style: solid solid none;
    border-width: 1px 1px 0;
    z-index: 1000;
    background: #fff;
    position: absolute;
    right: 20px;
    font-size: 11px;
    color: #767676;
    padding: 0;
    height: $hmd-tab-height;
    width: max-content;
    top: 6px;

    &.hmd2f-close {
        background: #008296;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: white;
        }
    }

    a {
        color: #767676;
        font-size: 11px;
        line-height: 24px;
        margin-left: 12px;
        margin-right: 2px;
        min-width: 35px;
        text-align: left;
        text-decoration: none;
    }
}

/* Feddback Trigger tab - left position */
.fc-left #hmd2f-trigger-tab {
    left: 20px;
    right: auto;
}

.fc-left #hmd2f-exit {
    right: 0;
    padding-right: 2px;
}

/* Feedback content */
#hmd2f-content-wrap {
    width: 465px;
    height: 497px;
    position: absolute;
    top: 30px;
    right: 0;
    background: #fff;
    border-bottom: 0;
    z-index: 99999;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    visibility: hidden;

    iframe {
        border: none;
    }
}
</style>
