<template>
    <div class="kat-input-root">
        <input
            v-bind="$attrs"
            :class="{ 'kat-input-field': true, 'kat-input-field-has-icon': hasIcon }"
            tabindex="0"
            type="text"
        />
        <KatIcon v-if="hasIcon" class="kat-input-icon" :name="iconName" />
    </div>
</template>

<script>
import KatIcon from './KatIcon.vue';

export default {
    name: 'KatInput',
    components: { KatIcon },
    props: {
        iconName: {
            type: String,
            required: false,
        },
    },
    computed: {
        hasIcon() {
            return this.iconName != null;
        },
    },
};
</script>

<style lang="scss" scoped>
.kat-input-root {
    position: relative;
}

.kat-input-field {
    border-radius: 1px;
    border: 1px solid $casper;
    box-shadow: inset 0 1px 2px 0 $input-box-shadow-color;
    box-sizing: border-box;
    color: $nordic;
    font-size: 0.8125rem;
    font-weight: 400;
    outline: none;
    padding: 0.5rem;
    width: 100%;

    &:focus {
        border: 1px solid $mystic;
    }

    &::placeholder {
        color: $tarpon;
        font-style: italic;
    }

    &-has-icon {
        text-indent: 20px;
    }
}

.kat-input-icon {
    filter: invert(76%) sepia(14%) saturate(168%) hue-rotate(136deg) brightness(95%) contrast(84%); // Using filter to recolor the SVG to $casper, see https://stackoverflow.com/a/59411103
    left: 5px;
    position: absolute;
    top: 7px;
}
</style>
