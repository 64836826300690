<template>
    <div :class="`header-logo ${logoClass}`" />
</template>

<script>
import { mapState } from 'pinia';
import KatalBreakpoints from '../../constants/KatalBreakpoints';
import { LoadingStates } from '../../enums/LoadingStates';
import { Site } from '../../enums/Site';
import { useContextStore } from '../../stores/contextStore';
import { useWindowStore } from '../../stores/windowStore';

const MARKETSCOPES_IN_EUROPE = ['BE', 'DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE', 'TR'];

export default {
    name: 'TheHeaderLogo',
    computed: {
        ...mapState(useContextStore, ['loadingState', 'marketscope', 'site']),
        ...mapState(useWindowStore, ['windowWidth']),
        logoClass() {
            if (this.windowWidth < KatalBreakpoints.MEDIUM || this.loadingState !== LoadingStates.loaded) {
                return 'header-logo--amazon';
            } else if (this.site === Site.ADVANTAGE) {
                if (this.marketscope === 'CA') {
                    return 'header-logo--advantage-ca';
                } else if (this.marketscope === 'JP') {
                    return 'header-logo--advantage-jp';
                } else if (MARKETSCOPES_IN_EUROPE.includes(this.marketscope)) {
                    return 'header-logo--advantage-eu';
                }
                return 'header-logo--advantage';
            }
            return MARKETSCOPES_IN_EUROPE.includes(this.marketscope)
                ? 'header-logo--vendorCentral-eu'
                : 'header-logo--vendorCentral';
        },
    },
};
</script>

<style lang="scss" scoped>
.header-logo {
    &--advantage {
        background-image: url('../../../images/advantage.svg');
        height: 34px;
        width: 146px;
    }

    &--advantage-ca {
        background-image: url('../../../images/advantage-ca.svg');
        height: 34px;
        width: 146px;
    }

    &--advantage-eu {
        background-image: url('../../../images/advantage-eu.svg');
        height: 34px;
        width: 146px;
    }

    &--advantage-jp {
        background-image: url('../../../images/advantage-jp.svg');
        height: 34px;
        width: 146px;
    }

    &--amazon {
        background-image: url('../../../images/amazon.svg');
        height: 30px;
        width: 63px;
    }

    &--vendorCentral {
        background-image: url('../../../images/vendorCentral.svg');
        height: 27px;
        width: 174px;
    }

    &--vendorCentral-eu {
        background-image: url('../../../images/vendorCentral-eu.svg');
        height: 30px;
        width: 174px;
    }
}
</style>
