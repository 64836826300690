import { defineStore } from 'pinia';

const TIMEOUT_DURATION_MS = 5000;

export const useToastStore = defineStore('toastStore', {
    state: () => ({
        label: null,
        timeout: null,
    }),
    actions: {
        setToast(label) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            const timeout = setTimeout(() => {
                this.dismissToast();
            }, TIMEOUT_DURATION_MS);
            this.label = label;
            this.timeout = timeout;
        },
        dismissToast() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.label = null;
            this.timeout = null;
        },
    },
});
