<template>
    <div class="side-nav-container">
        <FocusTrap :active="isOpen" :escape-deactivates="false" initial-focus=".side-nav-close-button">
            <transition name="side-nav-slide">
                <div v-if="isOpen" class="side-nav">
                    <div class="side-nav-header">
                        <div
                            class="side-nav-close-button"
                            data-test-tag="side-nav-close"
                            @click="onClickCloseButton"
                            @keyup.enter="onClickCloseButton"
                            @keyup.space="onClickCloseButton"
                            tabindex="0"
                            :aria-label="$t('common_button_close')"
                            role="button"
                        >
                            <KatIcon class="side-nav-close-button-icon" name="close" size="small" />
                        </div>
                        {{ $t('sideNav_header_menu') }}
                    </div>
                    <LoadingWrapper :loading-state="navigationLoadingState">
                        <div class="side-nav-body" data-test-tag="side-nav-body">
                            <div
                                v-for="navTab in navTabs"
                                ref="side-nav-tab"
                                class="side-nav-tab"
                                data-test-tag="nav-tab-container"
                                :key="navTab.menuId"
                                tabindex="0"
                                @keyup.enter="(e) => setActiveNavTab(e, navTab)"
                                @keyup.space="(e) => setActiveNavTab(e, navTab)"
                                @mouseenter="(e) => setActiveNavTab(e, navTab)"
                                @mouseleave="() => setActiveNavTab()"
                            >
                                <!-- Note: If we change the hierarchy here, we need to change the `setPositionOnHoveredSubNav` method below -->
                                <span class="side-nav-tab-label">{{ navTab.text }}</span>
                                <SideNavBadge class="side-nav-tab-badge" :nav-item="navTab" />
                                <div
                                    class="side-nav-tab-chevron"
                                    :class="{ 'side-nav-tab-chevron-hover': activeNavTabId === navTab.menuId }"
                                >
                                    <KatIcon class="side-nav-tab-chevron-icon" name="chevron-right" size="small" />
                                </div>
                                <!-- FlyoutMenu must be the last item in order for `setPositionOnHoveredSubNav` to compute properly -->
                                <FlyoutMenu
                                    :is-visible="activeNavTabId === navTab.menuId"
                                    :nav-tab="navTab"
                                    :triangleSpacingFromTop="triangleSpacingFromTop"
                                    :navMenuSpacingFromLeft="navMenuSpacingFromLeft"
                                    :navMenuSpacingFromTop="navMenuSpacingFromTop"
                                />
                            </div>
                        </div>
                    </LoadingWrapper>
                </div>
            </transition>
        </FocusTrap>
        <div class="side-nav-backdrop" :class="{ 'side-nav-backdrop-visible': isOpen }" @click="onClickCloseButton" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { LoadingStates } from '../../enums/LoadingStates';
import { useDismissalsAndExposuresStore } from '../../stores/dismissalsAndExposuresStore';
import { useNavigationStore } from '../../stores/navigationStore';
import { useSideNavStore } from '../../stores/sideNavStore';
import KatIcon from '../katal/KatIcon.vue';
import FlyoutMenu from './FlyoutMenu.vue';
import LoadingWrapper from './LoadingWrapper.vue';
import SideNavBadge from './SideNavBadge.vue';
import { FocusTrap } from 'focus-trap-vue';
import Flyout from './Flyout.vue';
import FavoritePageButton from './FavoritePageButton.vue';

export default {
    name: 'SideNav',
    components: { FavoritePageButton, Flyout, KatIcon, FlyoutMenu, LoadingWrapper, SideNavBadge, FocusTrap },
    data() {
        return {
            activeNavTabId: null,
            triangleSpacingFromTop: 0,
            navMenuSpacingFromLeft: 0,
            navMenuSpacingFromTop: 0,
            visibleNavTabTimeout: null,
            dismissalTimeout: null,
        };
    },
    computed: {
        ...mapState(useNavigationStore, {
            navigationLoadingState: 'loadingState',
            navigation: 'navigationWithoutSettingsMenu',
        }),
        ...mapState(useDismissalsAndExposuresStore, {
            dismissalsAndExposuresLoadingState: 'loadingState',
            isDismissed: 'isDismissed',
            isExposed: 'isExposed',
        }),
        ...mapState(useSideNavStore, ['isOpen']),
        navTabs() {
            return (this.navigation && this.navigation.subNav) || [];
        },
        isLoaded() {
            return (
                this.navigationLoadingState === LoadingStates.loaded &&
                this.dismissalsAndExposuresLoadingState === LoadingStates.loaded
            );
        },
    },
    methods: {
        ...mapActions(useNavigationStore, ['loadNavigation']),
        ...mapActions(useDismissalsAndExposuresStore, ['addDismissal', 'addExposure', 'loadDismissalsAndExposures']),
        ...mapActions(useSideNavStore, ['openSideNav', 'closeSideNav']),
        setActiveNavTab(e, navTab) {
            const targetElement = e ? e.currentTarget : null;
            clearTimeout(this.visibleNavTabTimeout);
            clearTimeout(this.dismissalTimeout);
            if (
                navTab &&
                navTab.dataAttributes &&
                navTab.dataAttributes.badgeType &&
                !this.isDismissed(navTab.menuId)
            ) {
                this.dismissalTimeout = setTimeout(() => {
                    this.addDismissal(navTab.menuId);
                }, 500); // Timeout set to avoid adding dismissals when user quickly scrolls over
            }
            if (this.activeNavTabId !== null) {
                this.visibleNavTabTimeout = setTimeout(() => {
                    if (!e && !navTab) {
                        this.activeNavTabId = null;
                    } else {
                        this.activeNavTabId = navTab.menuId;
                        this.setPositionOnHoveredSubNav(targetElement);
                    }
                }, 70);
            } else {
                if (!e && !navTab) {
                    this.activeNavTabId = null;
                } else {
                    this.activeNavTabId = navTab.menuId;
                    this.setPositionOnHoveredSubNav(targetElement);
                }
            }
        },
        /**
         * This method calculates the flyout menu's positioning based on the window size
         * Adjust the component's positioning appropriately for smaller vertical space
         */
        setPositionOnHoveredSubNav(target) {
            if (target && target.children) {
                const { top, left, right } = target.getBoundingClientRect();
                this.navMenuSpacingFromLeft = right - left;
                /**
                 * Grab the last element in our DOM tree under the class "side-nav-tab" in our template
                 * Note: If we change our hierarchy/structure, this element will change
                 */
                const subMenuElement = target.children[target.children.length - 1];
                const elementBox = subMenuElement.getBoundingClientRect();
                const sizeOfCategoryMenu = 50;
                const paddingSize = 20;
                const windowUpperBound = window.innerHeight; // y-axis end
                const overflowBottom = elementBox.height + top > windowUpperBound;
                // Recalculate the sub menu from the top when it cuts off at the window screen
                if (overflowBottom) {
                    const overflowSpace = elementBox.height + top - windowUpperBound;
                    // Set the new positioning to the amount of overflowed space from the bottom of the window
                    let targetTop = top - overflowSpace;
                    // If there is still extra space on top that exceeds a category menu size, add extra padding
                    if (targetTop > sizeOfCategoryMenu && windowUpperBound - top > sizeOfCategoryMenu + paddingSize) {
                        targetTop = targetTop - sizeOfCategoryMenu;
                    }
                    this.triangleSpacingFromTop = top;
                    this.navMenuSpacingFromTop = targetTop;
                } else {
                    this.triangleSpacingFromTop = top;
                    this.navMenuSpacingFromTop = top;
                }
            }
        },
        addTabExposures() {
            if (!this.isOpen || !this.isLoaded) {
                return;
            }
            for (const navTab of this.navTabs) {
                if (navTab.dataAttributes && navTab.dataAttributes.badgeType && !this.isExposed(navTab.menuId)) {
                    this.addExposure(navTab.menuId);
                }
            }
        },
        onClickCloseButton() {
            this.$metrics.newChildActionPublisherForMethod('LeftNavClosed').publishCounterMonitor('click', 1);
            this.closeSideNav();
        },
    },
    watch: {
        isLoaded() {
            this.addTabExposures();
        },
        isOpen() {
            if (this.isOpen) {
                document.querySelector('body').style.overflow = 'hidden';
                this.loadNavigation();
                this.loadDismissalsAndExposures();
                this.addTabExposures();
            } else {
                document.querySelector('body').style.overflow = 'initial';
                this.activeNavTabId = null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$nav-body-width: calc(min(242px, 50vw)); // On mobile, it should take up only half of the screen
$negative-nav-body-width: calc(max(-242px, -50vw));

.side-nav {
    background-color: white;
    border: none; // Override conflicting CSS style
    color: $kat-squid-ink-700;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    height: 100%;
    left: 0;
    line-height: 20px;
    position: fixed;
    top: 0;
    width: $nav-body-width;
    z-index: $nav-body-index;

    :deep(.loading-wrapper-error) {
        padding: 14px 20px;
    }

    &-slide {
        &-enter-active,
        &-leave-active {
            transition: left 450ms;
        }

        &-enter,
        &-leave-to {
            left: $negative-nav-body-width;
        }
    }

    &-body {
        box-sizing: border-box;
        flex: 1;
        height: calc(100vh - #{$navbar-height});
        overflow-y: auto;
        padding: 10px 0;
        width: $nav-body-width;
    }

    &-backdrop {
        background-color: rgba($night, 0.8);
        inset: 0;
        height: 100%;
        opacity: 0;
        transition-duration: 250ms;
        z-index: $nav-backdrop-index;

        &-visible {
            position: fixed;
            opacity: 1;
        }
    }

    &-header {
        align-items: center;
        border-bottom: 1px solid $athens;
        display: flex;
        height: $navbar-height;
        flex-shrink: 0;
        font-weight: $font-weight-bold;
        padding: 0 16px;
    }

    &-close-button {
        cursor: pointer;
        display: flex;
        margin-right: 8px;

        &-icon {
            filter: invert(13%) sepia(43%) saturate(556%) hue-rotate(172deg) brightness(92%) contrast(86%); // Using filter to recolor the SVG to $kat-squid-ink-700, see https://stackoverflow.com/a/59411103
        }
    }

    &-tab {
        align-items: center;
        background-color: white;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;
        padding: 10px 16px;

        &:hover {
            background-color: $kat-squid-ink-50;
        }

        &:focus,
        &:active:not(:focus-within) {
            box-shadow: inset 0 0 0 1px $kat-aqua-400;
            outline: none;
        }

        &-chevron {
            flex: 0 0 auto;
            height: 20px;
            width: 20px;

            &-icon {
                filter: invert(43%) sepia(8%) saturate(858%) hue-rotate(172deg) brightness(89%) contrast(87%); // Using filter to recolor the SVG to $kat-squid-ink-500, see https://stackoverflow.com/a/59411103
            }
        }

        &-label {
            display: flex;
            flex: 1 1 auto;
            margin-left: 4px; // Line up the label with the close icon in the header
            padding-right: 8px;
        }

        &-badge {
            flex: 0 0 auto;
            margin-right: 4px;
        }
    }
}
</style>
