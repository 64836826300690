import { defineStore } from 'pinia';
import { LoadingStates } from '../enums/LoadingStates';
import { getWithMetrics } from '../util/fetchWithMetrics';

const SETTINGS_MENU_ID = 'settings';
const BADGE_ATTRIBUTES = ['badgeType', 'badgeLabelId', 'badgeLabelText', 'badgeExpiration'];

function removeExpiredBadges(navItem) {
    if (navItem.dataAttributes && navItem.dataAttributes.badgeExpiration) {
        const currentDay = new Date().toISOString().slice(0, 10);
        if (currentDay > navItem.dataAttributes.badgeExpiration) {
            for (const badgeAttribute of BADGE_ATTRIBUTES) {
                delete navItem.dataAttributes[badgeAttribute];
            }
        }
    }
    for (const childNavItem of navItem.subNav || []) {
        removeExpiredBadges(childNavItem);
    }
}

export const useNavigationStore = defineStore('navigation', {
    state: () => ({
        loadingState: LoadingStates.notLoaded,
        errorMessage: '',
        navigation: null,
    }),
    getters: {
        navigationWithoutSettingsMenu(state) {
            if (state.loadingState !== LoadingStates.loaded) {
                return null;
            }
            return {
                ...state.navigation,
                subNav: [...state.navigation.subNav.filter((navTab) => navTab.menuId !== SETTINGS_MENU_ID)],
            };
        },
        settingsMenu(state) {
            if (state.loadingState !== LoadingStates.loaded) {
                return null;
            }
            return state.navigation.subNav.find((menu) => menu.menuId === SETTINGS_MENU_ID);
        },
    },
    actions: {
        async loadNavigation() {
            if (this.loadingState !== LoadingStates.notLoaded) {
                return;
            }
            this.loadingState = LoadingStates.loading;
            try {
                const output = await getWithMetrics('/trim/json/nav', {}, (payload) => payload.navigation);
                removeExpiredBadges(output.navigation);
                this.navigation = output.navigation;
                this.loadingState = LoadingStates.loaded;
            } catch (error) {
                this.errorMessage = `Error encountered while fetching navigation: [${error}]`;
                this.loadingState = LoadingStates.error;
            }
        },
    },
});
