import { defineStore } from 'pinia';
import { LoadingStates } from '../enums/LoadingStates';
import { getWithMetrics } from '../util/fetchWithMetrics';

export const useRegionalAccountStore = defineStore('regionalAccount', {
    state: () => ({
        loadingState: LoadingStates.notLoaded,
        regionalAccount: null,
    }),
    actions: {
        async loadRegionalAccount() {
            if (this.loadingState !== LoadingStates.notLoaded) {
                return;
            }
            this.loadingState = LoadingStates.loading;
            try {
                const payload = await getWithMetrics(
                    '/account-switcher/regional-account/vendorGroup',
                    {},
                    (payload) => payload.regionalAccount && payload.regionalAccount.label
                );
                this.loadingState = LoadingStates.loaded;
                this.regionalAccount = payload.regionalAccount;
            } catch (e) {
                this.loadingState = LoadingStates.error;
            }
        },
    },
});
