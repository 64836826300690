<template>
    <div class="locale-list-root">
        <div class="locale-list-header">
            <div class="locale-list-title">
                {{ $t('localeList_title') }}
            </div>
            <KatInput
                v-if="locales.length >= 8"
                class="locale-list-search"
                data-test-tag="locale-list-search"
                icon-name="search"
                :value="searchText"
                @input="searchText = $event.target.value"
            />
        </div>
        <div class="locale-list-body" :style="{ maxHeight: bodyMaxHeight }">
            <a
                v-for="locale in filteredLocales"
                :class="{ 'locale-list-item': true, 'locale-list-item-selected': isSelected(locale) }"
                :data-test-tag="`locale-list-item-${locale.code}`"
                :href="getHref(locale)"
                @click.prevent="onClickLocale(locale)"
                @keyup.enter="onClickLocale(locale)"
                :tabindex="isSelected(locale) ? -1 : 0"
            >
                <div class="locale-list-item-language">
                    {{ locale.nativeName }}
                </div>
                <div class="locale-list-item-country">
                    {{ getCountryLabel(locale) }}
                </div>
                <KatIcon v-if="isSelected(locale)" class="locale-list-item-checkmark" name="check" />
            </a>
            <div v-if="filteredLocales.length === 0" class="locale-list-no-results">
                {{ $t('localeList_body_noResults') }}
            </div>
        </div>
    </div>
</template>

<script>
import KatIcon from '../katal/KatIcon.vue';
import KatInput from '../katal/KatInput.vue';

const GAP_SIZE = 8; // Keep in sync with $gap-size
const ROW_HEIGHT = 58; // Keep in sync with $row-height
const MIN_ROWS = 1;
const MAX_ROWS = 8;

export default {
    name: 'TheLocaleList',
    components: {
        KatInput,
        KatIcon,
    },
    props: {
        closeFlyout: {
            type: Function,
            required: false,
        },
        locales: {
            type: Array,
            required: true,
        },
    },
    computed: {
        bodyMaxHeight() {
            const rows = Math.min(Math.max(Math.ceil(this.filteredLocales.length / 2), MIN_ROWS), MAX_ROWS);
            const height = ROW_HEIGHT * rows + GAP_SIZE * (rows - 1);
            return `${height}px`;
        },
        filteredLocales() {
            if (this.searchText === '') {
                return this.locales;
            }
            return this.locales.filter((locale) => {
                const searchTextLowerCase = this.searchText.toLowerCase();
                return (
                    locale.code.toLowerCase().includes(searchTextLowerCase) ||
                    locale.nativeName.toLowerCase().includes(searchTextLowerCase) ||
                    locale.countryName.toLowerCase().includes(searchTextLowerCase)
                );
            });
        },
    },
    data() {
        return {
            searchText: '',
            selectedLocale: this.$locale.replace('-', '_'),
        };
    },
    methods: {
        getCountryLabel(locale) {
            const localeCode = locale.code.replace('_', '-');
            return `${locale.countryName} (${localeCode})`;
        },
        getHref(locale) {
            const url = new URL(window.location);
            url.searchParams.set('mons_sel_locale', locale.code);
            return url.toString();
        },
        isSelected(locale) {
            return locale.code === this.selectedLocale;
        },
        onClickLocale(locale) {
            window.location = this.getHref(locale);
            this.closeFlyout && this.closeFlyout();
        },
    },
};
</script>

<style lang="scss" scoped>
$column-width: 174px;
$gap-size: 8px; // Keep in sync with GAP_SIZE
$row-height: 58px; // Keep in sync with ROW_HEIGHT

.locale-list {
    &-root {
        padding: $gap-size;

        :deep(.loading-wrapper-error) {
            width: $flyout-default-width;
        }
    }

    &-header {
        border-bottom: 1px solid $athens;
        display: flex;
        flex-direction: column;
        margin: (-$gap-size) (-$gap_size) $gap-size (-$gap-size);
        padding: $gap-size;
    }

    &-title {
        color: $nordic;
        flex: 1 1 auto;
        font-size: 14px;
        font-weight: $font-weight-bold;
        line-height: 20px;
    }

    &-search {
        margin-top: 4px;
    }

    &-body {
        display: grid;
        gap: $gap-size;
        grid-auto-flow: column; // Add additional columns as necessary
        grid-template-rows: repeat(auto-fit, minmax(0, $row-height)); // Empty rows are collapsed
        min-width: $column-width * 2 + $gap-size; // Minimum two columns
    }

    &-item {
        border: 1px solid $mystic;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        height: $row-height;
        justify-content: space-between;
        line-height: 20px;
        padding: $gap-size;
        position: relative;
        text-decoration: none;
        width: $column-width;

        &:hover {
            background-color: $azure;
        }

        &:active,
        &:focus {
            box-shadow: inset 0 0 0 2px $eastern;
            outline: none;
        }

        &-checkmark {
            filter: invert(1); // Using filter to recolor the SVG to white, see https://stackoverflow.com/a/59411103
            position: absolute;
            right: 5px;
            top: 5px;
        }

        &-country {
            color: $tarpon;
        }

        &-language {
            color: $nordic;
            font-weight: $font-weight-bold;
        }

        &-selected {
            background-color: $eastern;
            border-color: $eastern;
            pointer-events: none;

            div {
                color: white;
            }
        }
    }

    &-no-results {
        align-items: center;
        color: $tarpon;
        display: flex;
        font-size: 14px;
        font-style: italic;
        height: $row-height;
        justify-content: center;
        line-height: 20px;
    }
}
</style>
