<template>
    <div v-if="isLoaded">
        <slot />
    </div>
    <div v-else-if="isError" class="loading-wrapper-error">
        <div class="loading-wrapper-error-header">{{ $t('common_loadingWrapper_errorHeader') }}</div>
        {{ $t('common_loadingWrapper_errorBody') }}
    </div>
    <div v-else class="loading-wrapper-loading">
        <KatSpinner />
    </div>
</template>

<script>
import KatSpinner from '../katal/KatSpinner.vue';
import { LoadingStates } from '../../enums/LoadingStates';

export default {
    props: {
        loadingState: {
            type: String,
            required: true,
        },
    },
    computed: {
        isLoaded() {
            return this.loadingState === LoadingStates.loaded;
        },
        isError() {
            return this.loadingState === LoadingStates.error;
        },
    },
    components: {
        KatSpinner,
    },
};
</script>

<style lang="scss" scoped>
.loading-wrapper-error {
    box-sizing: border-box;
    color: $kat-squid-ink-700;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 400;
    height: 100%;
    line-height: 18px;
    width: 100%;

    &-header {
        font-size: 14px;
        font-weight: $font-weight-bold;
        line-height: 20px;
        margin-bottom: 8px;
    }
}

.loading-wrapper-loading {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}
</style>
