<template>
    <div
        v-if="isAuthorized && !isInSubmitted && !hidden && !dismissed"
        :class="{ 'favorite-pages-bar': true, 'favorite-pages-bar--editing': editing }"
    >
        <template v-if="isLoaded">
            <FavoritePagesEditor v-if="editing" :favorite-pages="favoritePages" @close="onEditorClose" />
            <template v-if="favoritePages.length > 0 && !editing">
                <div class="favorite-pages-full">
                    <KatIcon class="favorite-pages-full-icon" name="bookmark" size="tiny" />
                    <FavoritePagesLinks :favorite-pages="favoritePages" />
                </div>
                <KatButton
                    ref="editButton"
                    id="favorite-pages-edit-button"
                    :label="$t('common_button_edit')"
                    size="small"
                    variant="dark-secondary"
                    :on-click="onClickEdit"
                />
            </template>
            <template v-else-if="favoritePages.length === 0 && !editing">
                <div />
                <div class="favorite-pages-empty">
                    <i18n-t keypath="favoritePages_instructionsWithIcon">
                        <template #icon>
                            <KatIcon class="favorite-pages-empty-icon" name="bookmark_border" size="tiny" />
                        </template>
                    </i18n-t>
                </div>
                <div class="favorite-pages-hide" @click.stop="hideFavoritePages">
                    {{ $t('common_button_hide') }}
                </div>
            </template>
        </template>
        <template v-if="hasError">
            <div />
            <div class="favorite-pages-empty">
                <KatIcon class="favorite-pages-error-icon" name="info-circle-fill" size="tiny" />
                {{ $t('common_loadingWrapper_errorBody') }}
            </div>
            <div class="favorite-pages-dismiss" @click="dismissed = true">
                <KatIcon class="favorite-pages-dismiss-icon" name="close" size="small" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { LoadingStates } from '../../enums/LoadingStates';
import { useContextStore } from '../../stores/contextStore';
import { useVendorStatusStore } from '../../stores/vendorStatusStore';
import KatIcon from '../katal/KatIcon.vue';
import FavoritePagesEditor from './FavoritePagesEditor.vue';
import FavoritePagesLinks from './FavoritePagesLinks.vue';
import { useFavoritePagesStore } from '../../stores/favoritePagesStore';
import KatButton from '../katal/KatButton.vue';
import Flyout from './Flyout.vue';

export default {
    components: {
        Flyout,
        KatIcon,
        KatButton,
        FavoritePagesEditor,
        FavoritePagesLinks,
    },
    data() {
        return {
            dismissed: false, // Dismissed after a loading error; not permanently hidden
            editing: false,
        };
    },
    computed: {
        ...mapState(useFavoritePagesStore, ['favoritePages', 'hidden', 'loadingState']),
        ...mapState(useContextStore, ['isAuthorized']),
        ...mapState(useVendorStatusStore, ['isInSubmitted']),
        isLoaded() {
            return this.loadingState === LoadingStates.loaded;
        },
        hasError() {
            return this.loadingState === LoadingStates.error;
        },
    },
    methods: {
        ...mapActions(useFavoritePagesStore, ['hideFavoritePages', 'loadFavoritePages']),
        onClickEdit() {
            this.$metrics.newChildActionPublisherForMethod('FavoritePagesStartEdit').publishCounterMonitor('click', 1);
            this.editing = true;
        },
        onEditorClose() {
            this.editing = false;
            this.$nextTick(() => {
                this.$refs.editButton.$el.focus();
            });
        },
    },
    mounted() {
        this.loadFavoritePages();
    },
};
</script>

<style lang="scss" scoped>
.favorite-pages-bar {
    align-items: center;
    background-color: $kat-squid-ink-700;
    border-top: 1px solid $kat-squid-ink-500;
    box-sizing: content-box; // Override conflicting CSS style
    display: flex;
    font-size: 13px;
    height: 48px;
    justify-content: space-between;
    line-height: 18px;
    padding: 0 14px;

    &--editing {
        min-height: 48px;
        height: unset;
    }
}

.favorite-pages-full {
    align-items: center;
    display: flex;
    margin-right: 18px;
}

.favorite-pages-full-icon {
    filter: invert(1);
    flex: 0 0 auto;
    margin: 0 17px 0 3px; // Icon appears horizontally centered under the 48px nav button above it
}

.favorite-pages-empty {
    display: flex;
    align-items: center;
    color: $casper;
    height: 100%;
}

.favorite-pages-empty-icon {
    filter: invert(74%) sepia(14%) saturate(155%) hue-rotate(136deg) brightness(97%) contrast(88%); // $casper
    flex-shrink: 0;
    margin: 0 2px;
}

.favorite-pages-error-icon {
    filter: invert(1);
    flex-shrink: 0;
    margin-right: 10px;
}

.favorite-pages-hide {
    color: white;
    cursor: pointer;
    margin-left: 18px;

    &:hover {
        text-decoration: underline;
    }
}

.favorite-pages-dismiss {
    cursor: pointer;

    &-icon {
        filter: invert(1);
    }
}
</style>
