<template>
    <div class="favorite-pages-links-root">
        <div class="favorite-pages-links-list" id="favorite-pages-links-list" ref="links">
            <a
                v-for="page of accessibleFavoritePages"
                :data-page-id="page.pageId"
                :href="addReftag(page.path, page.ref, 'favb')"
                :tabindex="overflowPageIds.has(page.pageId) ? -1 : 0"
                :key="page.pageId"
                @click="onClickLink(page.pageId)"
            >
                {{ page.label }}
            </a>
        </div>
        <Flyout v-if="overflowPages.length > 0">
            <template #trigger>
                <KatIcon class="favorite-pages-overflow-icon" name="more_horiz" size="small" />
            </template>
            <div class="favorite-pages-overflow">
                <a
                    v-for="page of overflowPages"
                    :href="addReftag(page.path, page.ref, 'favb')"
                    @click="onClickLink(page.pageId)"
                >
                    {{ page.label }}
                </a>
            </div>
        </Flyout>
    </div>
</template>

<script>
import { addReftag } from '../../util/reftag';
import KatIcon from '../katal/KatIcon.vue';
import Flyout from './Flyout.vue';
import { mapState } from 'pinia';
import { useWindowStore } from '../../stores/windowStore';

export default {
    components: {
        Flyout,
        KatIcon,
    },
    props: {
        favoritePages: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            overflowPageIds: new Set(),
        };
    },
    computed: {
        ...mapState(useWindowStore, ['windowWidth']),
        accessibleFavoritePages() {
            return this.favoritePages.filter((page) => page.visible);
        },
        overflowPages() {
            return this.accessibleFavoritePages.filter((page) => this.overflowPageIds.has(page.pageId));
        },
    },
    methods: {
        addReftag,
        /**
         * Determine which page IDs should be shown in the overflow list by checking which of the favorite pages have
         * been wrapped to the second line of the flexbox parent.
         */
        calculateOverflowPageIds() {
            const firstRowTop = this.$refs.links.offsetTop + 2;
            const links = this.$refs.links.querySelectorAll('a');
            const overflowPageIds = new Set();
            for (const link of links) {
                if (link.offsetTop > firstRowTop) {
                    overflowPageIds.add(link.getAttribute('data-page-id'));
                }
            }
            this.overflowPageIds = overflowPageIds;
        },
        onClickLink(pageId) {
            const publisher = this.$metrics.newChildActionPublisherForMethod('FavoritePagesLink');
            publisher.publishCounterMonitor('click', 1);
            publisher.publishCounterMonitor(`page-${pageId}`, 1);
        },
    },
    watch: {
        accessibleFavoritePages() {
            // wait till the dom updates and 'causes' overflow before recalculating
            this.$nextTick(() => this.calculateOverflowPageIds());
        },
        windowWidth() {
            this.calculateOverflowPageIds();
        },
    },
    mounted() {
        this.calculateOverflowPageIds();
    },
};
</script>

<style lang="scss" scoped>
.favorite-pages-overflow {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 2px); // Fit within viewport
    overflow-y: auto; // Add scrollbar if contents are larger than viewport

    a {
        color: $kat-squid-ink-700;
        font-size: 13px;
        line-height: 18px;
        padding: 7px 14px;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
            background-color: $azure;
        }
    }
}

#favorite-pages-links-list.favorite-pages-links-list {
    // Override global style set on <a>
    box-sizing: content-box;
    display: flex;
    flex-wrap: wrap;
    height: 34px; // Must match the anchor's outer height to cause wrapping
    overflow-y: hidden;
    margin: 0 16px;
    padding: 2px; // Prevent overflow-y from hiding the outline applied when the <a> are keyboard focused

    a {
        padding: 8px 12px;
        color: white;
        cursor: pointer;
        font-size: 13px;
        line-height: 18px;
        margin-right: 18px;
        text-decoration: none;

        &:hover {
            outline: $default-outline $kat-white;
            border-radius: 1px;
        }

        &:focus {
            outline: $default-outline $kat-aqua-300;
            border-radius: 1px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.favorite-pages-links-root {
    align-items: center;
    display: flex;
}

.favorite-pages-overflow-icon {
    filter: invert(1);
    flex: 0 0 auto;
}
</style>
