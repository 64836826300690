<template>
    <button :class="['kat-button', `kat-button--${variant}`, `kat-button--${size}`]" @click="onClick">
        {{ label }}
        <KatIcon v-if="icon" class="kat-button-icon" :name="icon" size="small" />
    </button>
</template>

<script>
import KatIcon from './KatIcon.vue';

export default {
    name: 'KatButton',
    components: { KatIcon },
    props: {
        icon: {
            type: String,
            required: false,
        },
        label: String,
        size: {
            validator: (value) => ['base', 'small'].indexOf(value) !== -1,
            default: 'base',
        },
        variant: {
            validator: (value) =>
                ['primary', 'secondary', 'link', 'dark-primary', 'dark-secondary'].indexOf(value) !== -1,
            default: 'primary',
        },
        onClick: Function,
    },
};
</script>

<style lang="scss" scoped>
// CSS variables used in Katal components
$font-size-medium: 0.8125rem;
$padding-sm: 0.5rem;
$border-width-xxsmall: 1px;

// Styles used directly in the Katal Button component
.kat-button {
    border-radius: 0.1rem;
    border-style: solid;
    border-width: $border-width-xxsmall;
    box-shadow: 0 1px 2px 0 $shadow-gray;
    color: $white;
    cursor: pointer;
    font-family: $font-family-base;
    font-size: $font-size-medium;
    letter-spacing: 0.003rem;
    line-height: 1.25;
    padding: $padding-sm 1.5rem;
    text-align: center;
    white-space: nowrap;

    &--primary {
        background-color: $eastern;
        border-color: $eastern;
        color: $white;

        &:disabled {
            background-color: $submarine;
            border-color: $submarine;
            color: $white;
        }

        &:focus {
            outline: $default-outline $nordic;
        }

        &:hover {
            background-color: $lagoon;
            outline: $default-outline $lagoon;
        }
    }

    &--secondary {
        background-color: $zircon;
        border-color: $zircon;
        color: $nordic;

        &:disabled {
            background-color: $azure;
        }
    }

    &--link {
        background: $white;
        border-color: transparent;
        box-shadow: none;
        color: $nordic;

        &:disabled {
            background-color: $seattle;
            border-color: $seattle;
            color: #757575;
        }

        &:focus {
            outline: $default-outline $eastern;
        }

        &:hover {
            background-color: $zircon;
            color: $nordic;
            outline: $default-outline $zircon;
        }
    }

    &--dark-primary {
        background-color: $kat-aqua-400;
        border-color: transparent;
        box-shadow: none;
        color: $kat-white;

        &:focus {
            background-color: $kat-aqua-600;
            outline: $default-outline $kat-aqua-100;
            outline-offset: 3px;
        }

        &:hover {
            background-color: $kat-aqua-600;
        }
    }

    &--dark-secondary {
        background-color: $kat-squid-ink-700;
        border-color: $kat-squid-ink-500;
        box-shadow: none;
        color: $kat-white;
        fill: $kat-squid-ink-500;

        &:focus {
            background-color: $kat-aqua-400;
            border-color: transparent;
            outline: $default-outline $kat-aqua-100;
            outline-offset: 3px;
        }

        &:hover {
            background-color: $kat-aqua-400;
            border-color: transparent;
        }
    }

    &--small {
        border-radius: 1px;
        box-sizing: border-box;
        font-size: 13px;
        height: 24px;
        line-height: 18px;
        padding: 0 14px;
    }
}

.kat-button-icon {
    float: right;
}
</style>
